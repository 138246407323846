import { Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Input,
  LinearProgress,
  Typography,
} from "@mui/joy";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useGetAccountQuery, useUpdateAccountMutation } from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";

export default function SettingsSupportContact() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [updateAccount, updateAccountResult] = useUpdateAccountMutation();
  const dispatch = useDispatch();

  const {
    data: accountData,
    error,
    isLoading,
    isFetching,
  } = useGetAccountQuery();

  const onSubmit = (data) => {
    console.log(data);

    updateAccount({
      paymentGatewayData: {
        business_profile: {
          support_email: data.supportEmail,
          support_phone: data.supportPhone,
        },
      },
    });
  };

  useEffect(() => {
    if (updateAccountResult.isSuccess) {
      console.log("updated!");
      dispatch(
        triggerSuccessSnackbar({
          open: true,
          message: "Saved!",
        })
      );
    }
  }, [updateAccountResult]);

  return (
    <Grid container>
      <Grid xs={3}></Grid>
      <Grid xs={6}>
        {isLoading || isFetching ? (
          <Box mt={5}>
            <LinearProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {error && <Typography color="error">{error.message}</Typography>}
            <Card>
              <Typography gutterBottom level="h4">
                Support Contact
              </Typography>

              <Typography>
                You can change your support contact information here. These
                details will appear anywhere where we send the customer details
                about their bookings, such as emails.
              </Typography>

              <CardContent>
                <Box>
                  <Typography gutterBottom level="h5">
                    Support Email
                  </Typography>
                  <Input
                    placeholder="support@yourcompany.com"
                    type="email"
                    {...register("supportEmail", {
                      required: true,
                      value:
                        accountData?.paymentGatewayData?.business_profile
                          .support_email ?? "",
                    })}
                  />
                  {errors.supportEmail && (
                    <Typography
                      startDecorator={<Warning />}
                      mt={1}
                      level="body-sm"
                      color="warning"
                    >
                      Email is required
                    </Typography>
                  )}
                </Box>

                <hr />

                <Box>
                  <Typography gutterBottom level="h5">
                    Support Phone Number
                  </Typography>
                  <Input
                    placeholder="+441234567890"
                    type="tel"
                    {...register("supportPhone", {
                      required: true,
                      value:
                        accountData?.paymentGatewayData?.business_profile
                          .support_phone ?? "",
                    })}
                  />
                  {errors.supportPhone && (
                    <Typography
                      startDecorator={<Warning />}
                      mt={1}
                      level="body-sm"
                      color="warning"
                    >
                      Phone number is required
                    </Typography>
                  )}
                </Box>
              </CardContent>

              <CardActions>
                <Button type="submit" disabled={updateAccountResult.isLoading}>
                  Update
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Grid>
      <Grid xs={3}></Grid>
    </Grid>
  );
}
