import { RemoveCircleOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, IconButton, Input, Typography } from "@mui/joy";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function ListBuilder(props) {
  const [list, setList] = useState(props.value ?? []);
  const [newListDescription, setNewListDescription] = useState("");
  const { setValue } = useFormContext();

  const addListItem = () => {
    console.log(newListDescription);
    if (newListDescription === "") {
      alert("Please enter a list item");
      return;
    }

    let newArr = JSON.parse(JSON.stringify(list));
    newArr.push({
      description: newListDescription,
    });

    setList([...newArr]);
    setNewListDescription("");
    setValue(props.name, newArr);
  };

  const removeListItem = (idx) => {
    list.splice(idx, 1);
    setList([...list]);
    setValue(props.name, list);
  };

  return (
    <>
      <Box marginTop={1}>
        {list.map((charge, idx) => {
          return (
            <Grid
              spacing={2}
              container
              key={`listbuilder-${props.name}-${idx}`}
            >
              <Grid xs={11}>
                <Typography>{charge.description}</Typography>
              </Grid>
              <Grid justifyItems={"flex-end"} xs={1}>
                <IconButton
                  onClick={() => removeListItem(idx)}
                  aria-label="remove"
                  color="danger"
                  size="large"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Input
            value={newListDescription}
            placeholder="New item"
            fullWidth
            onChange={(e) => setNewListDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                e.preventDefault();
                addListItem();
              }
            }}
            endDecorator={
              <IconButton
                variant="soft"
                onClick={() => addListItem()}
                color="success"
              >
                <AddIcon />
              </IconButton>
            }
          />
        </Grid>
        {/* <Grid item xs={11}>
          <TextField
            value={newListDescription}
            placeholder="New item"
            type="text"
            fullWidth
            onChange={(e) => setNewListDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={addListItem}
            aria-label="add"
            variant="outlined"
            color="success"
            size="large"
          >
            <AddIcon />
          </IconButton>
        </Grid> */}
      </Grid>
    </>
  );
}
