/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import { DateTime } from "luxon";
import { useCreateAccommodationMutation } from "services/osare";
import { useCreateEventMutation } from "services/osare";
import { useCreateListingsMutation } from "services/osare";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/joy";

export default function PreCreateModal(props) {
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [initialName, setInitialName] = React.useState(
    `Draft created on ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)}`
  );
  const [createAccommodation, createAccommodationResult] =
    useCreateAccommodationMutation();
  const [createEvent, createEventResult] = useCreateEventMutation();
  const [createListing, createListingResult] = useCreateListingsMutation();

  React.useEffect(() => {
    if (createAccommodationResult.status === "fulfilled") {
      console.log("createAccommodationResult", createAccommodationResult);
      props.setOpen(false);
      navigate(`edit?id=${createAccommodationResult.data.id}`);
    }

    if (createEventResult.status === "fulfilled") {
      console.log("createEventResult", createEventResult);
      props.setOpen(false);
      navigate(`edit?id=${createEventResult.data.id}`);
    }

    if (createListingResult.status === "fulfilled") {
      console.log("createListingResult", createListingResult);
      props.setOpen(false);
      navigate(`edit?id=${createListingResult.data.id}`);
    }

    if (createAccommodationResult.status === "rejected") {
      setSaving(false);
    }

    if (createEventResult.status === "rejected") {
      setSaving(false);
    }

    if (createListingResult.status === "rejected") {
      setSaving(false);
    }
  }, [createAccommodationResult, createEventResult, createListingResult]);

  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Create new {props.type}</DialogTitle>
          <DialogContent>
            Lets start by giving your {props.type} a name.
          </DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setSaving(true);
              if (props.type === "accommodation") {
                createAccommodation({ name: initialName });
              }
              if (props.type === "event") {
                createEvent({ name: initialName });
              }
              if (props.type === "listing") {
                createListing({ name: initialName });
              }
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  disabled={saving}
                  autoFocus
                  required
                  value={initialName}
                  onChange={(e) => setInitialName(e.target.value)}
                />
              </FormControl>
              <Button
                startDecorator={
                  saving ? <CircularProgress color="neutral" /> : null
                }
                disabled={saving}
                type="submit"
              >
                {saving ? "Creating..." : "Create"}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
