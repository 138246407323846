import { Autocomplete } from "@mui/joy";

import { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function DietarySelector(props) {
  const { setValue } = useFormContext();
  const [availableDietaryItems] = useState([
    "Ayurvedic",
    "Fruitarian",
    "Gluten Free",
    "Halal",
    "Lacto-Ovo Vegetarian",
    "Lactose Free",
    "Naturopathic Diet",
    "Organic",
    "Paleo Diet",
    "Pescatarian",
    "Raw Food",
    "Regular Food (meat, poultry, fish)",
    "Seafood",
    "Vegan",
    "Vegetarian",
    "Whole Food",
    "Yogic Diet",
    "Other",
  ]);

  return (
    <>
      <Autocomplete
        id={props.name}
        options={availableDietaryItems}
        multiple
        defaultValue={props.value}
        onChange={(e, values) => {
          setValue(props.name, values);
        }}
      />
    </>
  );
}
