import { CheckCircle } from "@mui/icons-material";
import { Snackbar } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { triggerSuccessSnackbar } from "services/snackbars";

export default function SuccessSnackbar() {
  const successState = useSelector((state) => state.snackbars.successSnackbar);
  const dispatch = useDispatch();

  return (
    <Snackbar
      startDecorator={<CheckCircle />}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      variant="soft"
      color="success"
      autoHideDuration={3000}
      open={successState.open}
      onClose={() => dispatch(triggerSuccessSnackbar({ open: false }))}
    >
      {successState.message || "Success!"}
    </Snackbar>
  );
}
