/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/joy";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import StructureBuilder from "pages/Dash/Components/StructureBuilder/StructureBuilder";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCmsQuery,
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetAccommodationByIdQuery,
} from "services/osare";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardAccommodationRooms() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getAccommodationData, accommodationResponse] =
    useLazyGetAccommodationByIdQuery();
  const [saving, setSaving] = useState(false);
  const { data: cmsData } = useCmsQuery();

  const onSubmit = async (data) => {
    console.log("Form data", data);

    navigate(`/dash/wizard/${wizardId}/accommodation/location`);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    // if (data.introduction.blocks[0].text === "") {
    //   alert("Please fill in the introduction section");
    //   return;
    // }

    // if (data.highlights.blocks[0].text === "") {
    //   alert("Please fill in the highlights section");
    //   return;
    // }

    // setSaving(true);

    // await updateEventData({
    //   id: wizardData.results[0].eventId,
    //   updateData: {
    //     categories: data.categories,
    //     checkInTime: data.checkInTime,
    //     checkOutTime: data.checkOutTime,
    //     eventDaysLength: data.eventDaysLength,
    //     minimumGroupSize: data.minimumGroupSize,
    //     maximumGroupSize: data.maximumGroupSize,
    //   },
    // })
    //   .unwrap()
    //   .then((data) => {
    //     console.log("saved!");
    //     setSaving(false);

    //     dispatch(
    //       triggerSuccessSnackbar({
    //         open: true,
    //         message: "Saved!",
    //       })
    //     );

    //     navigate(`/dash/wizard/${wizardId}/listing/itinerary`);
    //   })
    //   .catch((error) => {
    //     console.error("Error updating event", error);
    //     setSaving(false);
    //     alert("Error updating event, please try again.");
    //   });
  };

  useEffect(() => {
    if (accommodationResponse.isSuccess && accommodationResponse.data) {
      console.log("Accommodation data", accommodationResponse.data);
      // formMethods.setValue("name", listingResponse.data.results[0].name);
    }
  }, [accommodationResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getAccommodationData(wizardData.results[0].accommodationId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      accommodationResponse.isSuccess &&
      !accommodationResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SectionHeader
              title={t("dashboard.accommodation.upsert.bookableSpaces.title")}
              subtitle={t(
                "dashboard.accommodation.upsert.bookableSpaces.description"
              )}
            />

            <Box marginY={2}>
              <StructureBuilder
                editMode={true}
                accommodationId={wizardData.results[0].accommodationId}
                accommodationData={accommodationResponse.data.results[0]}
                cmsData={cmsData}
                styles={accommodationResponse.data.results[0].styles}
              />
            </Box>

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
