/**
 * Docs: https://web.archive.org/web/20230701010714mp_/https://react-google-maps-api-docs.netlify.app/#marker
 */
import {
  Autocomplete,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

export default function LocationSelector(props) {
  const { setValue } = useFormContext();
  const [libraries] = useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBUflO7GfxzOqIT-ur40GGv2va9zMQiAic",
    libraries,
  });

  const [map, setMap] = useState(null);
  const [selectedMarkerLatLong, setSelectedMarkerLatLong] = useState({
    lat: props.value?.geometry?.lat ? props.value.geometry.lat : 51.509865,
    lng: props.value?.geometry?.lng ? props.value.geometry.lng : -0.118092,
  });

  console.log("selectedMarkerLatLong", selectedMarkerLatLong);

  const onLoadAutocomplete = useRef(null);

  const onLoad = useCallback(function callback(map) {
    map.setCenter(selectedMarkerLatLong);
    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            borderRadius: "8px",
          }}
          options={{
            disableDefaultUI: true,
          }}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          clickableIcons={false}
          center={selectedMarkerLatLong}
        >
          <MarkerF position={selectedMarkerLatLong} />
          <Autocomplete
            onLoad={(instance) => {
              onLoadAutocomplete.current = instance;
            }}
            onPlaceChanged={() => {
              const place = onLoadAutocomplete.current.getPlace();
              console.log("place", place);

              const locationData = {
                addressComponents: place.address_components,
                formattedAddress: place.formatted_address,
                geometry: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                },
                name: place.name,
                googlePlaceId: place.place_id,
              };

              if (map) {
                map.panTo({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });

                map.setZoom(16);
              }

              setSelectedMarkerLatLong({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });

              console.log("locationData", locationData);
              console.log("props.name", props.name);

              setValue(props.name, locationData);
            }}
          >
            <input
              type="text"
              placeholder={
                _.isEmpty(props?.value?.formattedAddress)
                  ? "Start typing an address..."
                  : props?.value?.formattedAddress
              }
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `80%`,
                height: `42px`,
                borderRadius: `5px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                paddingLeft: "10px",
                top: "10px",
                left: "10px",
              }}
            />
          </Autocomplete>
        </GoogleMap>
      ) : null}
    </>
  );
}
