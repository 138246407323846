import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { useEffect } from "react";
import { useCancelOrderMutation } from "services/osare";

export default function CancelOrderModal(props) {
  const [cancelOrder, cancelOrderStatus] = useCancelOrderMutation();

  useEffect(() => {
    console.log("cancelOrderStatus", cancelOrderStatus);
  }, [cancelOrderStatus]);

  console.log("CancelOrderModal", props);
  return (
    <Modal open={props.open} onClose={() => props.closeFn(false)}>
      <ModalDialog color="danger" variant="soft">
        <DialogTitle>Cancel this order</DialogTitle>
        <DialogContent>
          This order will be cancelled, any payments that have been made by the
          customer so far will be refunded and you will incur a charge for
          cancelling the order.
        </DialogContent>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            props.closeFn(false);
          }}
        >
          <Stack spacing={2}>
            <Button
              color="danger"
              type="submit"
              onClick={() => cancelOrder({ orderId: props._id })}
            >
              Refund and Cancel
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
