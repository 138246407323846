import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getAuth } from "firebase/auth";

const Logout = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const startLogout = async () => {
    console.log("Destroying current sessions, if any...");

    await auth.signOut();
    sessionStorage.clear();

    navigate("/login");
  };

  useEffect(() => {
    startLogout();
  });

  return <div>Logging out...</div>;
};

export default Logout;
