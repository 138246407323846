/* eslint-disable no-unused-vars */
// core
import { useEffect } from "react";

// libraries
import { Backdrop } from "@mui/material";

// Styles
import "react-calendar/dist/Calendar.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// redux / slices
import { Info, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import isListingComponentEnabled from "helpers/isListingComponentEnabled";
import AccommodationSelect from "pages/Dash/Components/AccommodationSelector/AccommodationSelector";
import AvailabilityBuilder from "pages/Dash/Components/AvailabilityBuilder/AvailabilityBuilder";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import DepositSelector from "pages/Dash/Components/DepositSelector/DepositSelector";
import EventSelect from "pages/Dash/Components/EventSelector/EventSelector";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import PolicySelector from "pages/Dash/Components/PolicySelector/PolicySelector";
import PossibleEarningsCalculator from "pages/Dash/Components/PossibleEarningsCalculator/PossibleEarningsCalculator";
import RequestToBookToggle from "pages/Dash/Components/RequestToBookToggle/RequestToBookToggle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAccommodationQuery,
  useGetConfigQuery,
  useGetEventsQuery,
  useLazyGetListingByIdQuery,
  useUpdateListingMutation,
} from "services/osare";

export default function DashListingsUpsert() {
  const formMethods = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: accommodationData } = useGetAccommodationQuery("");
  const { data: eventData } = useGetEventsQuery("");
  const [searchParams] = useSearchParams();
  const [updateListing, updateListingResult] = useUpdateListingMutation();
  const [getListingById, listingByIdResult] = useLazyGetListingByIdQuery();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  useEffect(() => {
    getListingById(searchParams.get("id"));
  }, []);

  const onSubmit = (data) => {
    console.log(data);

    updateListing({
      id: searchParams.get("id"),
      updateData: { ...data },
    });
  };

  // Edit Mode save
  useEffect(() => {
    if (updateListingResult.status === "fulfilled") {
      navigate("/dash/listings", {
        state: {
          saved: true,
        },
      });
    }
  }, [updateListingResult]);

  // Edit Mode
  useEffect(() => {
    if (listingByIdResult.status === "fulfilled") {
      console.log(
        "fetched data!",
        listingByIdResult.isSuccess && listingByIdResult.data.results[0]
      );

      Object.keys(
        listingByIdResult.isSuccess && listingByIdResult.data.results[0]
      ).forEach((key) => {
        formMethods.setValue(
          key,
          listingByIdResult.isSuccess && listingByIdResult.data.results[0][key]
        );
      });
    }
  }, [listingByIdResult.data, listingByIdResult.status]);

  return (
    <Grid container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={listingByIdResult.isFetching}
      >
        <CircularProgress />
      </Backdrop>
      {listingByIdResult.isSuccess ? (
        <>
          <Grid md={3}></Grid>
          <Grid xs={12} md={6}>
            <Box marginBottom={5} />
            <Card variant="outlined">
              <Typography gutterBottom level="title-lg">
                {t("dashboard.listings.upsert.intro.title")}
              </Typography>

              <Typography level="body-lg">
                {t("dashboard.listings.upsert.intro.description")}
              </Typography>
            </Card>
            <Box marginBottom={5} />
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                {isListingComponentEnabled(configData, "name") && (
                  <Box marginBottom={3}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.listings.upsert.name.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.listings.upsert.name.description")}
                    </Typography>

                    <Input
                      color={
                        formMethods.formState.errors.name?.type === "required"
                          ? "warning"
                          : "neutral"
                      }
                      name="name"
                      placeholder="Name of listing"
                      variant="outlined"
                      {...formMethods.register("name", { required: true })}
                    />

                    {formMethods.formState.errors["name"]?.type ===
                      "required" && (
                      <Box marginTop={1}>
                        <Alert startDecorator={<Warning />} color="warning">
                          Please choose a name for this listing
                        </Alert>
                      </Box>
                    )}
                  </Box>
                )}

                {isListingComponentEnabled(configData, "tagline") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.tagline.title")}
                    </Typography>

                    <Typography level="body-md" gutterBottom>
                      {t("dashboard.listings.upsert.tagline.description")}
                    </Typography>

                    <Input
                      color={
                        formMethods.formState.errors.tagline?.type ===
                        "required"
                          ? "warning"
                          : "neutral"
                      }
                      name="tagline"
                      placeholder="Tagline"
                      variant="outlined"
                      {...formMethods.register("tagline", { required: true })}
                    />

                    {formMethods.formState.errors["tagline"]?.type ===
                      "required" && (
                      <Box marginTop={1}>
                        <Alert startDecorator={<Warning />} color="warning">
                          Please choose a tagline for this listing
                        </Alert>
                      </Box>
                    )}
                  </Box>
                )}

                {isListingComponentEnabled(configData, "introduction") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.listingIntro.title")}
                    </Typography>

                    <Typography level="body-md" gutterBottom>
                      {t("dashboard.listings.upsert.listingIntro.description")}
                    </Typography>

                    <DashEditor
                      name="introduction"
                      contentState={
                        listingByIdResult.isSuccess &&
                        listingByIdResult.data.results[0].introduction
                      }
                    />

                    {formMethods.formState.errors["introduction"]?.type ===
                      "required" && (
                      <Box marginTop={1}>
                        <Alert startDecorator={<Warning />} color="warning">
                          Please choose a introduction for this listing
                        </Alert>
                      </Box>
                    )}
                  </Box>
                )}

                {isListingComponentEnabled(configData, "highlights") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.highlights.title")}
                    </Typography>

                    <Typography level="body-md" gutterBottom>
                      {t("dashboard.listings.upsert.highlights.description")}
                    </Typography>

                    <DashEditor
                      name="highlights"
                      contentState={
                        listingByIdResult.isSuccess &&
                        listingByIdResult.data.results[0].highlights
                      }
                    />
                  </Box>
                )}

                {isListingComponentEnabled(configData, "photos") && (
                  <Box marginBottom={3}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.listings.upsert.photos.title")}
                    </Typography>

                    <GalleryManager
                      collection="listings"
                      collectionId={searchParams.get("id")}
                    />
                  </Box>
                )}

                <Box marginBottom={3}>
                  <Typography level="title-lg">
                    {t("dashboard.listings.upsert.requestToBookMode.title")}
                  </Typography>
                  <Typography level="body-md">
                    {t(
                      "dashboard.listings.upsert.requestToBookMode.description"
                    )}
                  </Typography>

                  <RequestToBookToggle
                    name="ordersMustBeConfirmed"
                    value={
                      listingByIdResult.isSuccess &&
                      listingByIdResult.data.results[0].ordersMustBeConfirmed
                    }
                  />
                </Box>

                {isListingComponentEnabled(configData, "accommodation") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.accommodation.title")}
                    </Typography>
                    {listingByIdResult.isSuccess &&
                    listingByIdResult.data.results[0].isActive ? (
                      <Box marginY={2}>
                        <Alert startDecorator={<Info />} color="success">
                          This listing is currently being purchased, the
                          accommodation can no longer be changed.
                        </Alert>
                      </Box>
                    ) : (
                      <Typography level="body-md">
                        {t(
                          "dashboard.listings.upsert.accommodation.description"
                        )}
                      </Typography>
                    )}

                    <AccommodationSelect
                      name="accommodationIds"
                      listingData={
                        listingByIdResult.isSuccess &&
                        listingByIdResult.data.results[0]
                      }
                      accommodations={accommodationData?.results || []}
                    />
                  </Box>
                )}

                {isListingComponentEnabled(configData, "event") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.event.title")}
                    </Typography>
                    {listingByIdResult.isSuccess &&
                    listingByIdResult.data.results[0].isActive ? (
                      <Box marginY={2}>
                        <Alert startDecorator={<Info />} color="success">
                          This listing is currently being purchased, the event
                          can no longer be changed.
                        </Alert>
                      </Box>
                    ) : (
                      <Typography level="body-md">
                        {t("dashboard.listings.upsert.event.description")}
                      </Typography>
                    )}

                    <EventSelect
                      name="eventIds"
                      listingData={
                        listingByIdResult.isSuccess &&
                        listingByIdResult.data.results[0]
                      }
                      events={eventData?.results || []}
                    />
                  </Box>
                )}

                {isListingComponentEnabled(configData, "availability") && (
                  <Box marginBottom={3}>
                    <Typography level="title-lg">
                      {t("dashboard.listings.upsert.availability.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.listings.upsert.availability.description")}
                    </Typography>

                    {!formMethods.getValues("accommodationIds") ||
                      (formMethods.getValues("eventIds") && (
                        <AvailabilityBuilder
                          name="availability"
                          accommodationIds={formMethods.getValues(
                            "accommodationIds"
                          )}
                          eventIds={formMethods.getValues("eventIds")}
                          listingId={searchParams.get("id")}
                          listingData={
                            listingByIdResult.isSuccess &&
                            listingByIdResult.data.results[0]
                          }
                        />
                      ))}
                  </Box>
                )}

                <Box marginBottom={3}>
                  <Typography level="title-lg">
                    {t(
                      "dashboard.listings.upsert.paymentsAndRefundsPolicy.title"
                    )}
                  </Typography>
                  <Typography gutterBottom level="body-md">
                    {t(
                      "dashboard.listings.upsert.paymentsAndRefundsPolicy.description"
                    )}
                  </Typography>

                  <PolicySelector
                    listingData={
                      listingByIdResult.isSuccess &&
                      listingByIdResult.data.results[0]
                    }
                  />
                </Box>

                <Box margin={2} />

                <Box marginBottom={3}>
                  <Typography level="title-lg">
                    {t("dashboard.listings.upsert.deposit.title")}
                  </Typography>
                  <Typography gutterBottom level="body-md">
                    {t("dashboard.listings.upsert.deposit.description")}
                  </Typography>

                  <DepositSelector
                    listingData={
                      listingByIdResult.isSuccess &&
                      listingByIdResult.data.results[0]
                    }
                  />
                </Box>

                <Box marginBottom={3}>
                  <Typography level="title-lg">
                    {t("dashboard.listings.upsert.possibleEarnings.title")}
                  </Typography>
                  <Typography gutterBottom level="body-md">
                    {t(
                      "dashboard.listings.upsert.possibleEarnings.description"
                    )}
                  </Typography>

                  {/* <code>
                    {JSON.stringify(formMethods.getValues("inventory"), "", 2)}
                  </code> */}

                  <PossibleEarningsCalculator
                    accommodationIds={
                      listingByIdResult.isSuccess &&
                      listingByIdResult.data.results[0].accommodationIds
                    }
                    listingId={searchParams.get("id")}
                  />
                </Box>

                <Card>
                  <Button variant="soft" type="submit">
                    {"Save"}
                  </Button>
                </Card>
              </form>
            </FormProvider>
          </Grid>
          <Grid md={"3"}></Grid>
        </>
      ) : null}
    </Grid>
  );
}
