import Box from "@mui/material/Box";
import * as React from "react";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { Warning } from "@mui/icons-material";
import { Alert, Option, Select } from "@mui/joy";
import { useFormContext } from "react-hook-form";

export default function EventSelect(props) {
  const formMethods = useFormContext();
  const [selectedEvent, setSelectedEvent] = React.useState(
    props.listingData.eventIds
      ? props.listingData.eventIds
      : ["event-select-disabled"]
  );

  return (
    <Box>
      <Select
        color={
          formMethods.formState.errors[props.name]?.type === "required" &&
          !selectedEvent
            ? "warning"
            : "neutral"
        }
        disabled={props.listingData.isActive}
        defaultValue={"event-select-disabled"}
        value={selectedEvent[0]}
        label="Event"
        onChange={(e, v) => {
          formMethods.setValue(props.name, [v]);
          setSelectedEvent([v]);
        }}
      >
        <Option
          disabled
          key={`event-select-disabled`}
          value={`event-select-disabled`}
        >
          Please select an event
        </Option>
        {props.events.map((a) => (
          <Option key={a._id} value={a._id}>
            {a.name}
          </Option>
        ))}
      </Select>
      {selectedEvent.length === 0 && (
        <Box marginTop={1}>
          <Alert startDecorator={<Warning />} color="warning">
            Please select an event from the list above.
          </Alert>
        </Box>
      )}
    </Box>
  );
}
