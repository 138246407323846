import { Snackbar } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { triggerWarningSnackbar } from "services/snackbars";

export default function WarningSnackbar() {
  const warningState = useSelector((state) => state.snackbars.warningSnackbar);
  const dispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      variant="soft"
      color="warning"
      autoHideDuration={3000}
      open={warningState.open}
      onClose={() => dispatch(triggerWarningSnackbar({ open: false }))}
    >
      {warningState.message || "Warning!"}
    </Snackbar>
  );
}
