/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import isEventComponentEnabled from "helpers/isEventComponentEnabled";
import isListingComponentEnabled from "helpers/isListingComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import RetreatTypeSelector from "pages/Dash/Components/RetreatTypeSelector/RetreatTypeSeletor";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import StaffChooser from "pages/Dash/Components/StaffChooser/StaffChooser";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetEventByIdQuery,
  useLazyGetListingByIdQuery,
  useUpdateEventMutation,
  useUpdateListingMutation,
  useUpdateWizardMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardListingsTheBasics() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getListingData, listingResponse] = useLazyGetListingByIdQuery();
  const [getEventData, eventResponse] = useLazyGetEventByIdQuery();
  const [updateListingData, updateListingResponse] = useUpdateListingMutation();
  const [updateWizardData] = useUpdateWizardMutation();
  const [updateEventData, updateEventResponse] = useUpdateEventMutation();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data) => {
    console.log("Form data", data);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (data.introduction.blocks[0].text === "") {
      alert("Please fill in the introduction section");
      return;
    }

    if (data.highlights.blocks[0].text === "") {
      alert("Please fill in the highlights section");
      return;
    }

    setSaving(true);

    await updateWizardData({
      id: wizardId,
      updateData: {
        listingTitle: data.name,
      },
    })
      .unwrap()
      .then(() => {
        console.log("Wizard accommodation title updated!");
      })
      .catch((error) => {
        console.error("Error updating wizard accommodation title", error);
      });

    await updateListingData({
      id: wizardData.results[0].listingId,
      updateData: {
        name: data.name,
        tagline: data.tagline,
        introduction: data.introduction,
        highlights: data.highlights,
        eventIds: [wizardData.results[0].eventId],
        accommodationIds: [wizardData.results[0].accommodationId],
        askForInsurance: data.askForInsurance,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating listing, please try again.");
      });

    console.log("listing data has been updated!");

    await updateEventData({
      id: wizardData.results[0].eventId,
      updateData: {
        staffIds: data.staffIds,
        categories: data.categories,
        checkInTime: data.checkInTime,
        checkOutTime: data.checkOutTime,
        eventDaysLength: data.eventDaysLength,
        minimumGroupSize: data.minimumGroupSize,
        maximumGroupSize: data.maximumGroupSize,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);

        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );

        navigate(`/dash/wizard/${wizardId}/listing/itinerary`);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating event, please try again.");
      });
  };

  useEffect(() => {
    if (listingResponse.isSuccess && listingResponse.data) {
      console.log("Listing data", listingResponse.data);
      formMethods.setValue("name", listingResponse.data.results[0].name);
      formMethods.setValue("tagline", listingResponse.data.results[0].tagline);
      formMethods.setValue(
        "introduction",
        listingResponse.data.results[0].introduction
      );
      formMethods.setValue(
        "highlights",
        listingResponse.data.results[0].highlights
      );

      formMethods.setValue(
        "askForInsurance",
        listingResponse.data.results[0].askForInsurance || false
      );
    }

    if (eventResponse.isSuccess && eventResponse.data) {
      console.log("Event data", eventResponse.data);
      formMethods.setValue("staffIds", eventResponse.data.results[0].staffIds);

      formMethods.setValue(
        "categories",
        eventResponse.data.results[0].categories
      );
      formMethods.setValue(
        "checkInTime",
        eventResponse.data.results[0].checkInTime
      );
      formMethods.setValue(
        "checkOutTime",
        eventResponse.data.results[0].checkOutTime
      );
      formMethods.setValue(
        "eventDaysLength",
        eventResponse.data.results[0].eventDaysLength
      );
      formMethods.setValue(
        "minimumGroupSize",
        eventResponse.data.results[0].minimumGroupSize
      );
      formMethods.setValue(
        "maximumGroupSize",
        eventResponse.data.results[0].maximumGroupSize
      );
    }
  }, [listingResponse, eventResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getListingData(wizardData.results[0].listingId);
      getEventData(wizardData.results[0].eventId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      listingResponse.isSuccess &&
      !listingResponse.isFetching &&
      !eventResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SectionHeader
              title="The Basics"
              subtitle="Provide all important information about the program your retreat
                offers. This thorough description helps guests understand the
                full scope of what your retreat offers, aiding them in deciding
                if it aligns with their interests and needs."
            />

            {isListingComponentEnabled(configData, "name") && (
              <Box marginY={2}>
                <Typography level="title-lg">
                  {t("dashboard.listings.upsert.name.title")}
                </Typography>

                <Typography level="body-md">
                  {t("dashboard.listings.upsert.name.description")}
                </Typography>

                <Box m={2} />

                <Input
                  color={
                    formMethods.formState.errors.name?.type === "required"
                      ? "warning"
                      : "neutral"
                  }
                  name="name"
                  placeholder="Name of listing"
                  variant="outlined"
                  {...formMethods.register("name", {
                    required: true,
                  })}
                />
              </Box>
            )}

            {isListingComponentEnabled(configData, "tagline") && (
              <Box marginY={2}>
                <Typography level="title-lg">
                  {t("dashboard.listings.upsert.tagline.title")}
                </Typography>

                <Typography level="body-md">
                  {t("dashboard.listings.upsert.tagline.description")}
                </Typography>

                <Box m={2} />

                <Input
                  color={
                    formMethods.formState.errors.tagline?.type === "required"
                      ? "warning"
                      : "neutral"
                  }
                  name="tagline"
                  placeholder="Tagline"
                  required
                  variant="outlined"
                  {...formMethods.register("tagline", {
                    required: true,
                  })}
                />
              </Box>
            )}

            {isListingComponentEnabled(configData, "introduction") && (
              <Box marginBottom={3}>
                <Typography level="title-lg">
                  {t("dashboard.listings.upsert.listingIntro.title")}
                </Typography>

                <Typography level="body-md" gutterBottom>
                  {t("dashboard.listings.upsert.listingIntro.description")}
                </Typography>

                <DashEditor
                  name="introduction"
                  contentState={listingResponse.data.results[0].introduction}
                  required
                />
              </Box>
            )}

            {isListingComponentEnabled(configData, "highlights") && (
              <Box marginBottom={3}>
                <Typography level="title-lg">
                  {t("dashboard.listings.upsert.highlights.title")}
                </Typography>

                <Typography level="body-md" gutterBottom>
                  {t("dashboard.listings.upsert.highlights.description")}
                </Typography>

                <DashEditor
                  required
                  name="highlights"
                  contentState={listingResponse.data.results[0].highlights}
                />
              </Box>
            )}

            {/**
             * From Events
             */}

            {isEventComponentEnabled(configData, "photos") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.photos.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.photos.description")}
                </Typography>

                <GalleryManager
                  collection="events"
                  collectionId={wizardData.results[0].eventId}
                />
              </Box>
            )}

            {/**
             * From Events
             */}
            {isEventComponentEnabled(configData, "retreatCategory") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.eventType.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.eventType.description")}
                </Typography>

                <RetreatTypeSelector
                  required
                  name="categories"
                  value={eventResponse.data.results[0].categories}
                />
              </Box>
            )}

            {/**
             * From Events
             */}
            {isEventComponentEnabled(configData, "arrivalInformation") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.arrivalInformation.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.events.upsert.arrivalInformation.description")}
                </Typography>

                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <FormControl>
                      <FormHelperText>Check-in Time</FormHelperText>
                      <Input
                        type="time"
                        name="checkInTime"
                        required
                        {...formMethods.register("checkInTime", {
                          required: true,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl>
                      <FormHelperText>Check-out Time</FormHelperText>
                      <Input
                        type="time"
                        name="checkOutTime"
                        required
                        {...formMethods.register("checkOutTime", {
                          required: true,
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/**
             * From Events - Itinerary and Schedule
             */}
            {/* <Box marginBottom={3}>
              <Typography gutterBottom level="title-lg">
                {t("dashboard.events.upsert.itineraryScheduleIntro.title")}
              </Typography>

              <Typography gutterBottom level="body-lg">
                {t(
                  "dashboard.events.upsert.itineraryScheduleIntro.description"
                )}
              </Typography>
            </Box> */}

            {/**
             * Events: Event Duration
             */}

            {isEventComponentEnabled(configData, "eventDuration") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.eventDuration.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.eventDuration.description")}
                </Typography>

                <Input
                  required
                  size="lg"
                  sx={{ width: 100 }}
                  name="eventDaysLength"
                  type="number"
                  slotProps={{ input: { min: 1 } }}
                  label="Length of event in days"
                  {...formMethods.register("eventDaysLength", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </Box>
            )}

            {/**
             * Events: Minimum and Maximum Group Size
             */}

            {isEventComponentEnabled(configData, "minimumGroupSize") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.minimumGroupSize.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.minimumGroupSize.description")}
                </Typography>

                <Input
                  required
                  size="lg"
                  sx={{ width: 100 }}
                  type="number"
                  slotProps={{ input: { min: 1 } }}
                  name="minimumGroupSize"
                  label="Minimum"
                  {...formMethods.register("minimumGroupSize", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </Box>
            )}

            {/**
             * Events: Maximum Group Size
             */}

            {isEventComponentEnabled(configData, "maximumGroupSize") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.maximumGroupSize.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.maximumGroupSize.description")}
                </Typography>

                <Input
                  required
                  size="lg"
                  sx={{ width: 100 }}
                  type="number"
                  slotProps={{ input: { min: 1 } }}
                  name="maximumGroupSize"
                  label="Maximum"
                  {...formMethods.register("maximumGroupSize", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </Box>
            )}

            {isEventComponentEnabled(configData, "staff") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.staff.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.staff.description")}
                </Typography>

                <StaffChooser name="staffIds" />
              </Box>
            )}

            <Box marginBottom={3}>
              <Typography gutterBottom level="title-lg">
                Insurance
              </Typography>

              <Typography gutterBottom level="body-md">
                Ask the booking guest to ensure that they will have appropriate
                insurance before the date of travel
              </Typography>

              <Checkbox
                label={"Ask the booking guest to ensure they have insurance"}
                value={true}
                checked={formMethods.watch("askForInsurance")}
                {...formMethods.register("askForInsurance", {
                  required: false,
                })}
              />
            </Box>

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
