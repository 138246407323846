/* eslint-disable no-unused-vars */
import React from "react";

import { Autocomplete } from "@mui/joy";
import { useFormContext } from "react-hook-form";

export default function FaChooser(props) {
  const { setValue } = useFormContext();

  return (
    <>
      {props.cmsData && (
        <Autocomplete
          multiple
          id={props.name}
          options={props.cmsData.results.types?.[props.cmsDataKey].map(
            (v) => v.data.name[0].text
          )}
          defaultValue={props.existingData[props.name]}
          onChange={(event, newValue) => setValue(props.name, newValue)}
        />
      )}
    </>
  );
}
