/* eslint-disable no-unused-vars */
import { AssignmentReturn, MoneyOff, Paid } from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGetPoliciesQuery } from "services/osare";
import DataErrorComponent from "../DataError/DataError";

export default function PolicySelector(props) {
  const { setValue } = useFormContext();
  const {
    data: policiesData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useGetPoliciesQuery();

  useEffect(() => {
    /**
     * If this was a new listing with no policy ID
     * set the default policy ID to the second
     * policy (moderate)
     */
    if (isSuccess && policiesData.results.length > 0) {
      console.log("PolicySelector was successful, policiesData:", policiesData);
      if (!props.listingData.policyId) {
        console.log("no polcity ID selected, setting to second policy");
        setValue("policyId", policiesData.results[1]._id);
      }
    }
  }, [isSuccess]);

  const SelectionComponent = () => {
    return (
      <>
        {error ? (
          <DataErrorComponent retryTrigger={refetch} />
        ) : (
          <FormControl>
            <RadioGroup
              name="radio-buttons-group"
              defaultValue={
                props.listingData.policyId || policiesData.results[1]._id
              }
              onChange={(e) => {
                setValue("policyId", e.target.value);
              }}
            >
              {!isLoading &&
                policiesData.results.map((policy) => (
                  <Box key={`policy-${policy.name}`} marginBottom={1}>
                    <Radio value={policy._id} label={policy.name} />
                    <FormHelperText id="female-helper-text">
                      {policy.description}
                    </FormHelperText>
                    <FormHelperText>
                      <Typography
                        color="warning"
                        level="body-sm"
                        startDecorator={<MoneyOff />}
                      >
                        Non-refundable platform deposit and commission charged
                        immediately
                      </Typography>
                    </FormHelperText>
                    {policy.isPayingFullPackageCostAsap && (
                      <FormHelperText>
                        <Typography level="body-sm" startDecorator={<Paid />}>
                          Remaining package cost charged{" "}
                          <Chip color="primary">immediately</Chip> after
                          authorising payment
                        </Typography>
                      </FormHelperText>
                    )}
                    {!policy.isPayingFullPackageCostAsap && (
                      <FormHelperText>
                        <Typography level="body-sm" startDecorator={<Paid />}>
                          Remaining package cost charged{" "}
                          <Chip color="primary">
                            {policy.remainingBalanceChargeInDaysBeforeTravel}{" "}
                            days
                          </Chip>{" "}
                          before the travel date
                        </Typography>
                      </FormHelperText>
                    )}
                    <FormHelperText>
                      <Typography
                        level="body-sm"
                        startDecorator={<AssignmentReturn />}
                      >
                        Refunds are allowed on the remaining balance only.
                      </Typography>
                    </FormHelperText>
                    <FormHelperText>
                      <Typography level="body-sm">
                        Will refund{" "}
                        <Chip color="primary">
                          {policy.refundPercentAt14DaysBeforeTravelDate}%
                        </Chip>
                        at 14 days before the travel date,{" "}
                        <Chip color="primary">
                          {policy.refundPercentAt30DaysBeforeTravelDate}%
                        </Chip>{" "}
                        at 30 days,{" "}
                        <Chip color="primary">
                          {policy.refundPercentAt60DaysBeforeTravelDate}%
                        </Chip>
                        at 60 days,{" "}
                      </Typography>
                    </FormHelperText>
                  </Box>
                ))}
            </RadioGroup>
          </FormControl>
        )}
      </>
    );
  };

  return <Card>{isLoading ? <LinearProgress /> : <SelectionComponent />}</Card>;
}
