import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetConfigQuery } from "services/osare";

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: configData,
    isLoading: isConfigLoading,
    isFetching: isConfigFetching,
    error: configError,
  } = useGetConfigQuery();

  return (
    <>
      {configError ? (
        <Grid container>
          <Grid xs={12}>
            <Card color="danger">
              <Typography level="title-lg">Sorry...</Typography>
              <CardContent>
                <Typography gutterBottom>
                  There was an issue loading the core configuration of the
                  service. We cannot continue without it.
                </Typography>
                <Typography fontFamily={"monospace"}>
                  The error was: {configError.error}
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => window.location.reload()}>
                  Try again...
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid xs={12}>
            <Box margin={5} />
            <Typography level="h1" textAlign={"center"}>
              {isConfigLoading || isConfigFetching ? "..." : configData.name}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography level={"title-lg"} textAlign={"center"}>
              {t("home.intro")}
            </Typography>
          </Grid>
          <Grid xs={12} marginTop={5}>
            <Box textAlign={"center"}>
              <Button
                disabled={isConfigLoading || isConfigFetching}
                color="success"
                onClick={() => navigate("/login")}
                variant="solid"
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
