/* eslint-disable no-unused-vars */
import {
  Check,
  HourglassBottom,
  Receipt,
  Refresh,
  ScheduleSend,
  SubdirectoryArrowRight,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/joy";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { useGetPayoutsQuery } from "services/osare";

export default function Payouts() {
  const {
    data: payoutsData,
    isLoading,
    isFetching,
    isError,
    refetch: payoutsRefetch,
  } = useGetPayoutsQuery();
  const navigate = useNavigate();

  const ScheduledPaymentItem = ({ paymentItem }) => {
    return (
      <Box>
        <Typography level="title-lg">
          {paymentItem.purpose === "platformAndDepositFee"
            ? "Deposit"
            : "Remaining package cost"}
        </Typography>
        <Typography>
          Charge for GBP {paymentItem.chargeAmount}, attempted on{" "}
          {DateTime.fromMillis(paymentItem.onOrAfterTimestamp).toLocaleString(
            DateTime.DATE_FULL
          )}
        </Typography>
        <Typography
          component={"div"}
          startDecorator={<SubdirectoryArrowRight />}
        >
          Customer charge:{" "}
          <Chip color="success">{paymentItem.status.toUpperCase()}</Chip>
        </Typography>
        {paymentItem.resolvedPayoutData && (
          <Box>
            <Typography level="body-lg">Payout status</Typography>
            <Typography startDecorator={<SubdirectoryArrowRight />}>
              Status:{" "}
              <Chip
                color={
                  paymentItem.resolvedPayoutData.status === "paid"
                    ? "success"
                    : "neutral"
                }
              >
                {paymentItem.resolvedPayoutData.status.toUpperCase()}
              </Chip>
            </Typography>
            <Typography pr={3} startDecorator={<Check />}>
              Started on:{" "}
              {DateTime.fromSeconds(
                paymentItem.resolvedPayoutData.created
              ).toLocaleString(DateTime.DATE_FULL)}{" "}
              and expected to arrive on:{" "}
              {DateTime.fromSeconds(
                paymentItem.resolvedPayoutData.arrival_date
              ).toLocaleString(DateTime.DATE_FULL)}
            </Typography>
          </Box>
        )}

        {paymentItem.lastPayoutErrorCode && (
          <Typography gutterBottom color="warning" startDecorator={<Warning />}>
            The last attempt to pay out failed{" "}
            {DateTime.fromMillis(paymentItem.updatedAt).toRelative()} with error
            code: {paymentItem.lastPayoutErrorCode}
          </Typography>
        )}

        {!paymentItem.resolvedPayoutData && (
          <>
            <Typography color="warning" startDecorator={<HourglassBottom />}>
              The payout is scheduled{" "}
              {paymentItem.payoutAfterTimestamp &&
                DateTime.fromMillis(
                  paymentItem.payoutAfterTimestamp
                ).toRelative()}
              {paymentItem.lastPayoutErrorCode && "and will try again soon"}
            </Typography>
            {paymentItem.payoutAfterTimestamp && (
              <Typography startDecorator={<ScheduleSend />}>
                Originally scheduled:{" "}
                {DateTime.fromMillis(
                  paymentItem.payoutAfterTimestamp
                ).toRelative()}
              </Typography>
            )}
          </>
        )}
      </Box>
    );
  };

  const OrderItem = ({ orderData }) => {
    return (
      <Box mb={2}>
        <Card>
          <Typography startDecorator={<Receipt />} level="title-lg">
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => navigate(`/dash/orders/single?id=${orderData[0]}`)}
            >
              Order #{orderData[0]}
            </Link>
          </Typography>
          {orderData[1].map((payment) => (
            <ScheduledPaymentItem
              key={`payment-id-${payment._id}}`}
              paymentItem={payment}
            />
          ))}
        </Card>
      </Box>
    );
  };

  return (
    <Grid container mt={3}>
      <Grid md={3}></Grid>
      <Grid xs={12} md={6}>
        <Typography
          level="h2"
          gutterBottom
          endDecorator={
            <IconButton onClick={() => payoutsRefetch()}>
              <Refresh />
            </IconButton>
          }
        >
          Payouts
        </Typography>

        {(isLoading || isFetching) && <LinearProgress />}

        {!isLoading &&
          !isError &&
          payoutsData &&
          Object.entries(payoutsData).map((orderData, idx) => (
            <OrderItem key={`order-id-${idx}`} orderData={orderData} />
          ))}
      </Grid>
      <Grid md={3}></Grid>
    </Grid>
  );
}
