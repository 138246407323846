/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Input,
  Typography,
} from "@mui/joy";
import isAccommodationComponentEnabled from "helpers/isAccommodationComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import FaChooser from "pages/Dash/Components/FaChooser/FaChooser";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCmsQuery,
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetAccommodationByIdQuery,
  useUpdateAccommodationMutation,
  useUpdateWizardMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardAccommodationOverview() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getAccommodationData, accommodationResponse] =
    useLazyGetAccommodationByIdQuery();
  const [updateAccommodationData, updateAccommodationResponse] =
    useUpdateAccommodationMutation();
  const [updateWizardData] = useUpdateWizardMutation();
  const [saving, setSaving] = useState(false);
  const { data: cmsData } = useCmsQuery();

  const onSubmit = async (data) => {
    console.log("Form data:", data);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (data.description.blocks[0].text === "") {
      alert("Please fill in the description section");
      return;
    }

    if (!data.styles || data.styles.length === 0) {
      alert("Please select at least one accommodation category");
      return;
    }

    if (!data.faSustainability || data.faSustainability.length === 0) {
      alert("Please select at least one sustainability option");
      return;
    }

    setSaving(true);

    await updateWizardData({
      id: wizardId,
      updateData: {
        accommodationTitle: data.name,
      },
    })
      .unwrap()
      .then(() => {
        console.log("Wizard accommodation title updated!");
      })
      .catch((error) => {
        console.error("Error updating wizard accommodation title", error);
      });

    await updateAccommodationData({
      id: wizardData.results[0].accommodationId,
      updateData: data,
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);

        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );

        navigate(`/dash/wizard/${wizardId}/accommodation/rooms`);
      })
      .catch((error) => {
        console.error("Error updating accommodation", error);
        setSaving(false);
        alert("Error updating accommodation, please try again.");
      });
  };

  useEffect(() => {
    if (accommodationResponse.isSuccess && accommodationResponse.data) {
      console.log("Accommodation data", accommodationResponse.data);

      Object.keys(accommodationResponse.data.results[0]).forEach((key) => {
        formMethods.setValue(key, accommodationResponse.data.results[0][key]);
      });
    }
  }, [accommodationResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getAccommodationData(wizardData.results[0].accommodationId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      accommodationResponse.isSuccess &&
      !accommodationResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Card>
              <Typography level="body-lg">The Basics</Typography>
              <Typography level="body-md">
                Provide all important information about the program your retreat
                offers. This thorough description helps guests understand the
                full scope of what your retreat offers, aiding them in deciding
                if it aligns with their interests and needs.
              </Typography>
            </Card>

            {isAccommodationComponentEnabled(configData, "name") && (
              <Box marginY={2}>
                <Typography level="body-lg">
                  {t("dashboard.accommodation.upsert.name.title")}
                </Typography>

                <Typography level="body-md">
                  {t("dashboard.accommodation.upsert.name.description")}
                </Typography>

                <Box m={2} />

                <Input
                  color={
                    formMethods.formState.errors.name?.type === "required"
                      ? "warning"
                      : "neutral"
                  }
                  name="name"
                  placeholder="Name of accommodation"
                  variant="outlined"
                  {...formMethods.register("name", {
                    required: true,
                  })}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "description") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.description.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.accommodation.upsert.description.description")}
                </Typography>

                <DashEditor
                  name="description"
                  contentState={
                    accommodationResponse.data.results[0].description
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "category") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.category.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.category.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationStyle"}
                  name={"styles"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "photos") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.photos.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.accommodation.upsert.photos.description")}
                </Typography>

                <GalleryManager
                  collection={"accommodation"}
                  collectionId={wizardData.results[0].accommodationId}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "facilitiesAmenities"
            ) && (
              <Box marginY={2}>
                <Card variant="outlined">
                  <Typography gutterBottom level="title-lg">
                    {t(
                      "dashboard.accommodation.upsert.facilitiesAmenities.title"
                    )}
                  </Typography>

                  <Typography gutterBottom level="body-lg">
                    {t(
                      "dashboard.accommodation.upsert.facilitiesAmenities.description"
                    )}
                  </Typography>
                </Card>
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "basics") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.basics.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.basics.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationBasics"}
                  name={"faBasics"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "services") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.services.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.services.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationServices"}
                  name={"faServices"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "indoorSpaces") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.indoorSpaces.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.indoorSpaces.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationIndoor"}
                  name={"faIndoor"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "outdoorSpaces") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.outdoorSpaces.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.outdoorSpaces.description"
                  )}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationOutdoor"}
                  name={"faOutdoor"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "dining") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.dining.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.dining.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationFoodDrink"}
                  name={"faFoodDrink"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "activitiesFitness"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.activitiesFitness.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.activitiesFitness.description"
                  )}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationActivitiesFitness"}
                  name={"faActivitiesFitness"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "spaBeauty") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.spaBeauty.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.spaBeauty.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationSpaBeauty"}
                  name={"faSpaBeauty"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "rentals") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.rentals.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.rentals.description")}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationRentals"}
                  name={"faRentals"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "sustailability") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.sustainability.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.sustainability.description"
                  )}
                </Typography>

                <FaChooser
                  cmsData={cmsData}
                  cmsDataKey={"accommodationSustainability"}
                  name={"faSustainability"}
                  existingData={accommodationResponse.data.results[0]}
                />
              </Box>
            )}

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
