import { Box, CircularProgress, Typography } from "@mui/joy";

export default function WizardSectionLoading() {
  return (
    <Box m={0}>
      <Typography
        startDecorator={<CircularProgress size="sm" />}
        level="title-md"
      >
        Just a moment...
      </Typography>
    </Box>
  );
}
