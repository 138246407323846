import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorSnackbar: {
    open: false,
    autoHideDuration: 3000,
    message: "",
  },
  warningSnackbar: {
    open: false,
    autoHideDuration: 3000,
    message: "",
  },
  infoSnackbar: {
    open: false,
    autoHideDuration: 3000,
    message: "",
  },
  successSnackbar: {
    open: false,
    autoHideDuration: 3000,
    message: "",
  },
};

export const snackbarSlice = createSlice({
  name: "snackbars",
  initialState,
  reducers: {
    triggerWarningSnackbar: (state, action) => {
      state.warningSnackbar = {
        ...state.warningSnackbar,
        ...action.payload,
      };
    },
    triggerSuccessSnackbar: (state, action) => {
      state.successSnackbar = {
        ...state.successSnackbar,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { triggerWarningSnackbar, triggerSuccessSnackbar } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
