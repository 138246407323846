/* eslint-disable no-unused-vars */
// core
import { useEffect, useState } from "react";

// libraries
// import { Backdrop } from "@mui/material";

// Styles
import "react-calendar/dist/Calendar.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// redux / slices
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import FaChooser from "pages/Dash/Components/FaChooser/FaChooser";
import StructureBuilder from "pages/Dash/Components/StructureBuilder/StructureBuilder";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  // CircularProgress,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import { Backdrop } from "@mui/material";
import isAccommodationComponentEnabled from "helpers/isAccommodationComponentEnabled";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import LocationSelector from "pages/Dash/Components/LocationSelector/LocationSelector";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCmsQuery,
  useGetConfigQuery,
  useLazyGetAccommodationByIdQuery,
  useUpdateAccommodationMutation,
} from "services/osare";

export default function DashAccommodationUpsert() {
  const navigate = useNavigate();
  const formMethods = useForm();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [editMode] = useState(!!searchParams.get("id"));
  const [accommodationData, setAccommodationData] = useState({});
  const { data: cmsData } = useCmsQuery();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  const [updateAccommodation, updateAccommodationResult] =
    useUpdateAccommodationMutation();
  const [trigger, result] = useLazyGetAccommodationByIdQuery();

  // Edit Mode save
  useEffect(() => {
    if (updateAccommodationResult.status === "fulfilled") {
      navigate("/dash/accommodation", {
        state: {
          saved: true,
        },
      });
    }
  }, [updateAccommodationResult]);

  // Edit Mode
  useEffect(() => {
    console.log("Search Params", searchParams.get("id"));
    console.log("Edit Mode?", editMode);

    if (editMode && result.status !== "fulfilled") {
      console.log("Triggering single record fetch...");
      trigger(searchParams.get("id"));
    }

    if (result.status === "fulfilled") {
      console.log("fetched data!", result.data.results[0]);
      setAccommodationData(result.data.results[0]);

      Object.keys(result.data.results[0]).forEach((key) => {
        formMethods.setValue(key, result.data.results[0][key]);
      });
    }
  }, [editMode, result.data, result.status, searchParams, trigger]);

  const onSubmit = (values) => {
    console.log(values);
    console.log(Object.keys(values));

    values.geoJson = {
      type: "Point",
      coordinates: [values.location.geometry.lat, values.location.geometry.lng],
    };

    values.locationObscured = true;

    if (editMode) {
      console.log("Updating...");
      updateAccommodation({
        id: searchParams.get("id"),
        updateData: values,
      });
    }
  };

  return (
    <Grid container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editMode && !accommodationData.name}
      >
        {<CircularProgress />}
      </Backdrop>
      {(editMode && accommodationData.name) || !editMode ? (
        <>
          <Grid md={3}></Grid>
          <Grid xs={12} md={6}>
            <Box marginBottom={5} />
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Card variant="outlined">
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.intro.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t("dashboard.accommodation.upsert.intro.description")}
                      </Typography>
                    </Card>
                  </Grid>

                  {isAccommodationComponentEnabled(configData, "name") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.name.title")}
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        {t("dashboard.accommodation.upsert.name.description")}
                      </Typography>

                      <Input
                        fullwidth
                        name="name"
                        placeholder="Name of accommodation"
                        variant="outlined"
                        {...formMethods.register("name")}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "description"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.description.title")}
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        {t(
                          "dashboard.accommodation.upsert.description.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="description"
                        contentState={accommodationData.description}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "category") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.category.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.category.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationStyle"}
                        name={"styles"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "mapSelector"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        Location
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        Explanation of location
                      </Typography>

                      <LocationSelector
                        name="location"
                        value={accommodationData.location}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "photos") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.photos.title")}
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        {t("dashboard.accommodation.upsert.photos.description")}
                      </Typography>

                      <GalleryManager
                        collection={"accommodation"}
                        collectionId={searchParams.get("id")}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "facilitiesAmenities"
                  ) && (
                    <Grid xs={12}>
                      <Card variant="outlined">
                        <Typography gutterBottom level="title-lg">
                          {t(
                            "dashboard.accommodation.upsert.facilitiesAmenities.title"
                          )}
                        </Typography>

                        <Typography gutterBottom level="body-lg">
                          {t(
                            "dashboard.accommodation.upsert.facilitiesAmenities.description"
                          )}
                        </Typography>
                      </Card>
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "basics") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.basics.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t("dashboard.accommodation.upsert.basics.description")}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationBasics"}
                        name={"faBasics"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "services") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.services.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.services.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationServices"}
                        name={"faServices"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "indoorSpaces"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.indoorSpaces.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.indoorSpaces.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationIndoor"}
                        name={"faIndoor"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "outdoorSpaces"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.outdoorSpaces.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.outdoorSpaces.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationOutdoor"}
                        name={"faOutdoor"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "dining") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.dining.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t("dashboard.accommodation.upsert.dining.description")}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationFoodDrink"}
                        name={"faFoodDrink"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "activitiesFitness"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.activitiesFitness.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.activitiesFitness.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationActivitiesFitness"}
                        name={"faActivitiesFitness"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "spaBeauty") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.spaBeauty.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.spaBeauty.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationSpaBeauty"}
                        name={"faSpaBeauty"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(configData, "rentals") && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.rentals.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.rentals.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationRentals"}
                        name={"faRentals"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "sustailability"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.sustainability.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.sustainability.description"
                        )}
                      </Typography>

                      <FaChooser
                        cmsData={cmsData}
                        cmsDataKey={"accommodationSustainability"}
                        name={"faSustainability"}
                        existingData={accommodationData}
                      />
                    </Grid>
                  )}

                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.accommodation.upsert.bookableSpaces.title")}
                    </Typography>

                    <Typography gutterBottom level="body-lg">
                      {t(
                        "dashboard.accommodation.upsert.bookableSpaces.description"
                      )}
                    </Typography>

                    <StructureBuilder
                      editMode={editMode}
                      accommodationId={searchParams.get("id")}
                      accommodationData={accommodationData}
                      cmsData={cmsData}
                      styles={accommodationData.styles}
                      onChange={(d) => {
                        console.log(d);
                      }}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <Card variant="outlined">
                      <Typography gutterBottom level="title-lg">
                        Advisories
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        Some helpful information here about the above
                      </Typography>
                    </Card>
                  </Grid>

                  {isAccommodationComponentEnabled(
                    configData,
                    "generalAdvisories"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        General
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        Some helpful information here about the above
                      </Typography>

                      <DashEditor
                        name="generalAdvisory"
                        contentState={accommodationData.generalAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "locationAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.location.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.location.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="localAreaAdvisory"
                        contentState={accommodationData.localAreaAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "seasonalAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.accommodation.upsert.seasonal.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.seasonal.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="seasonalAdvisory"
                        contentState={accommodationData.seasonalAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "directionsAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.directionsAdvisory.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.directionsAdvisory.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="directionsAdvisory"
                        contentState={accommodationData.directionsAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "weatherAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.weatherAdvisory.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.weatherAdvisory.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="weatherAdvisory"
                        contentState={accommodationData.weatherAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "whatToBringAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.whatToBringAdvisory.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.whatToBringAdvisory.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="whatToBringAdvisory"
                        contentState={accommodationData.whatToBringAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "vaccinationsAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.vaccinationsAdvisory.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.vaccinationsAdvisory.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="vaccinationsAdvisory"
                        contentState={accommodationData.vaccinationsAdvisory}
                      />
                    </Grid>
                  )}

                  {isAccommodationComponentEnabled(
                    configData,
                    "insuranceAdvisory"
                  ) && (
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        {t(
                          "dashboard.accommodation.upsert.insuranceAdvisory.title"
                        )}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t(
                          "dashboard.accommodation.upsert.insuranceAdvisory.description"
                        )}
                      </Typography>

                      <DashEditor
                        name="insuranceAdvisory"
                        contentState={accommodationData.insuranceAdvisory}
                      />
                    </Grid>
                  )}

                  <Box margin={2} />

                  <Grid xs={12}>
                    <Card>
                      <Button variant="soft" type="submit">
                        {editMode ? "Update" : "Save"}
                      </Button>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
          <Grid md={"3"}></Grid>
        </>
      ) : null}
    </Grid>
  );
}
