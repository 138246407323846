import { Autocomplete } from "@mui/joy";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function MealsSelector(props) {
  const { setValue } = useFormContext();
  const [availableMeals] = useState([
    "Breakfast",
    "Brunch",
    "Lunch",
    "Dinner",
    "Snacks",
    "Drinks",
  ]);

  return (
    <>
      <Autocomplete
        id={props.name}
        options={availableMeals}
        multiple
        defaultValue={props.value}
        onChange={(e, values) => {
          setValue(props.name, values);
        }}
      />
    </>
  );
}
