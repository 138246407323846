import { Box, Card, Chip, Slider, Typography } from "@mui/joy";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function DepositSelector(props) {
  console.log("DepositSelector", props);
  const { setValue, getValues } = useFormContext();
  const [deposit, setDeposit] = React.useState(
    props.listingData.depositPercentage || 20
  );

  return (
    <Card>
      <Box marginX={3}>
        <Slider
          key={`deposit-slider`}
          onChange={(e, v) => {
            setDeposit(v);
            setValue("depositPercentage", v);
          }}
          orientation="horizontal"
          size="md"
          value={getValues("depositPercentage") || 20}
          valueLabelDisplay="auto"
          variant="solid"
          step={10}
          marks
          min={20}
          max={50}
        />
        <Typography textAlign={"center"}>
          Deposit will be <Chip color="primary">{deposit}%</Chip> of the total
          booking amount
        </Typography>
      </Box>
    </Card>
  );
}
