/* eslint-disable no-unused-vars */
import {
  PaymentRounded,
  Percent,
  RemoveCircle,
  ShoppingBag,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  Chip,
  Divider,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useFindAvailabilityByQuery, useGetRoomsQuery } from "services/osare";

export default function PossibleEarningsCalculator(props) {
  console.log("PossibleEarningsCalculator", props);

  if (!props.accommodationIds || props.accommodationIds.length === 0) {
    return (
      <Alert color="warning">
        Accommodation IDs are required to calculate earnings.
      </Alert>
    );
  }

  const { watch } = useFormContext();
  const { data: roomsData } = useGetRoomsQuery({ query: [] });
  const [inventory, setInventory] = React.useState([]);
  const depositPercentage = watch("depositPercentage", 20);
  const [maxAccommodationEarnings, setMaxAccommodationEarnings] =
    React.useState(0);
  const [maxDeposit, setMaxDeposit] = React.useState(0);
  const [maxCommission, setMaxCommission] = React.useState(0);
  const inventoryUpdatedAt = watch("inventoryUpdatedAt");

  useEffect(() => {
    if (inventoryUpdatedAt) {
      try {
        refetchAvailability();
      } catch (error) {
        console.warn(error);
      }
    }
  }, [inventoryUpdatedAt]);

  const {
    data: availabilityData,
    isLoading: isAvailabilityDataLoading,
    isFetching: isAvailabilityDataFetching,
    refetch: refetchAvailability,
  } = useFindAvailabilityByQuery(
    {
      associatedListingId: props.listingId,
      associatedAccommodationId: props.accommodationIds[0],
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    if (availabilityData) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flatMap
      let inventory = availabilityData.results.flatMap((a) => [...a.inventory]);

      const maxAccommodationEarning = inventory
        ?.map((a) => a.quantity * a.price)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      setInventory(inventory);
      setMaxDeposit(maxAccommodationEarning * (depositPercentage / 100));
      setMaxCommission(maxAccommodationEarning * 0.18);
      setMaxAccommodationEarnings(maxAccommodationEarning);
    }
  }, [depositPercentage, availabilityData]);

  return (
    <>
      <Box mt={5}>
        <Card>
          <Divider>Inventory Calculations</Divider>
          {inventory?.length === 0 && (
            <Alert color="warning">
              Please create your availability ranges first, then we can show you
              your calculations.
            </Alert>
          )}
          <Tabs aria-label="Basic tabs">
            <TabList
              sx={{
                overflow: "auto",
                scrollSnapType: "x mandatory",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {inventory?.map((i) => (
                <Tab
                  sx={{ flex: "none", scrollSnapAlign: "start" }}
                  indicatorInset
                  key={`earnings-calculator-room-tab${i._id}`}
                >
                  {
                    roomsData?.results.find((r) => r._id === i.associatedRoomId)
                      ?.name
                  }{" "}
                  <Chip size="sm" variant="soft" color={"primary"}>
                    {new DateTime.fromMillis(
                      availabilityData.results.find(
                        (a) => a._id === i.associatedAvailabilityId
                      ).fromTimestamp
                    ).toFormat("d LLL")}
                  </Chip>
                </Tab>
              ))}
            </TabList>
            {inventory?.map((a, i) => {
              // This is the deposit percentage that the merchant
              // has chosen to charge the customer
              const chosenDepositPercentage = depositPercentage / 100;
              // This is how much the platform will take as commission
              const commissionPercantage = 0.18;

              /**
               * First, work out the deposit charge before commission...
               */
              const bookingDepositChargeBeforeCommission =
                a.price * chosenDepositPercentage;

              /**
               * Next, work out the platform commission charge, which is the
               * 18% of the total package price...
               */
              const platformCommissionCharge = a.price * commissionPercantage;

              /**
               * Next, subtract the platform commission from the deposit charge.
               * Note: We always take our commission from the despoit amount.
               */
              const bookingDepositCharge =
                a.price * chosenDepositPercentage - platformCommissionCharge;

              /**
               * Finally, work out the remaining amount that will be sent to
               * the merchant on the day of the customers travel.
               */
              const remainingAmount =
                a.price - (bookingDepositCharge + platformCommissionCharge);

              return (
                <TabPanel key={`max-earnings-panel-${a._id}`} value={i}>
                  <Box>
                    {!a.price || !a.quantity ? (
                      <Alert
                        key={`max-earnings-zero-item-${a._id}`}
                        color="warning"
                      >
                        Please set both the price and quantity to see
                        calculations.
                      </Alert>
                    ) : (
                      <>
                        <Typography marginBottom={2}>
                          For each{" "}
                          <Chip color="primary">
                            <Typography fontFamily={"monospace"}>
                              GBP {a.price}
                            </Typography>{" "}
                          </Chip>
                          purchase of this room:
                        </Typography>

                        <Typography
                          color={"primary"}
                          level={"body-sm"}
                          startDecorator={<Percent />}
                        >
                          You have set a{" "}
                          <Chip color="primary">
                            {depositPercentage}% deposit
                          </Chip>
                          , which equals:
                        </Typography>
                        <Typography
                          fontFamily={"monospace"}
                          color={"primary"}
                          component={"div"}
                          level={"body-lg"}
                          marginLeft={3.5}
                        >
                          GBP {bookingDepositChargeBeforeCommission.toFixed(2)}
                        </Typography>
                        <Typography
                          color={"warning"}
                          level={"body-sm"}
                          startDecorator={<RemoveCircle />}
                        >
                          From the above {depositPercentage}% deposit - we take
                          our <Chip color="warning">18% commission</Chip>, which
                          is:
                        </Typography>
                        <Typography
                          fontFamily={"monospace"}
                          color={"warning"}
                          component={"div"}
                          level={"body-lg"}
                          marginLeft={3.5}
                        >
                          GBP {platformCommissionCharge.toFixed(2)}
                        </Typography>
                        <Typography
                          color={"success"}
                          level={"body-sm"}
                          startDecorator={<PaymentRounded />}
                        >
                          This results in a
                          <b>
                            {" "}
                            &nbsp;final deposit, paid out to you at the time of
                            booking
                          </b>
                          , of:
                        </Typography>
                        <Typography
                          fontFamily={"monospace"}
                          color={"success"}
                          component={"div"}
                          level={"body-lg"}
                          marginLeft={3.5}
                        >
                          GBP {bookingDepositCharge.toFixed(2)}
                        </Typography>
                        <Typography
                          color={"success"}
                          level={"body-sm"}
                          startDecorator={<PaymentRounded />}
                        >
                          On the <b> &nbsp;date of customer travel</b>, we will{" "}
                          <b>&nbsp;send you the remaining package cost</b>:
                        </Typography>
                        <Typography
                          fontFamily={"monospace"}
                          color={"success"}
                          component={"div"}
                          level={"body-lg"}
                          marginLeft={3.5}
                        >
                          GBP {remainingAmount.toFixed(2)}
                        </Typography>
                      </>
                    )}
                  </Box>
                </TabPanel>
              );
            })}
          </Tabs>
        </Card>
      </Box>

      <Box mt={5}>
        <Card>
          {/**
           * Total Deposits and Commissions
           */}
          {inventory && (
            <>
              <Divider>Total Deposits and Commissions</Divider>
              <Typography level={"body-sm"} startDecorator={<ShoppingBag />}>
                Maximum earnings based on inventory above:
              </Typography>
              <Typography
                fontFamily={"monospace"}
                level={"body-lg"}
                marginLeft={3.5}
              >
                GBP {maxAccommodationEarnings.toFixed(2)}
              </Typography>
              <Typography
                color={"primary"}
                level={"body-sm"}
                startDecorator={<Percent />}
              >
                You have set the deposit percentage to {depositPercentage}%,
                which is:
              </Typography>
              <Typography
                fontFamily={"monospace"}
                color={"primary"}
                component={"div"}
                level={"body-lg"}
                marginLeft={3.5}
              >
                GBP {maxDeposit.toFixed(2)}
              </Typography>

              <Typography
                color={"warning"}
                level={"body-sm"}
                startDecorator={<Percent />}
              >
                From the above deposit - we take our{" "}
                <Chip color="warning">18%</Chip>
                commission
              </Typography>
              <Typography
                fontFamily={"monospace"}
                color={"warning"}
                component={"div"}
                level={"body-lg"}
                marginLeft={3.5}
              >
                GBP {maxCommission.toFixed(2)}
              </Typography>

              <Typography
                color={"success"}
                level={"body-sm"}
                startDecorator={<PaymentRounded />}
              >
                This leaves you with a remaining deposit of
              </Typography>
              <Typography
                fontFamily={"monospace"}
                color={"success"}
                component={"div"}
                level={"body-lg"}
                marginLeft={3.5}
              >
                GBP {(maxDeposit - maxCommission).toFixed(2)}
              </Typography>

              <Typography
                color={"success"}
                level={"body-sm"}
                startDecorator={<PaymentRounded />}
              >
                The maximum possible payout would be:
              </Typography>
              <Typography
                fontFamily={"monospace"}
                color={"success"}
                component={"div"}
                level={"body-lg"}
                marginLeft={3.5}
              >
                GBP {(maxAccommodationEarnings - maxCommission).toFixed(2)}
              </Typography>
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
