/* eslint-disable no-unused-vars */

import { Refresh } from "@mui/icons-material";
import { Card, CircularProgress, Grid, Link, Typography } from "@mui/joy";
import DineroFactory from "dinero.js";
import { useGetBalanceQuery } from "services/osare";

export default function BalanceViewer() {
  const {
    data: balanceData,
    refetch: balanceRefetch,
    isLoading,
    isFetching,
    error,
  } = useGetBalanceQuery();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <Card variant="outlined">
          {(isLoading || isFetching) && !error ? (
            <CircularProgress />
          ) : (
            <>
              <Typography color="warning" level="title-md">
                Pending funds{" "}
                <Link
                  startDecorator={<Refresh />}
                  onClick={() => balanceRefetch()}
                ></Link>
              </Typography>
              {error ? (
                <Typography color="warning">
                  Could not fetch balance data,{" "}
                  <Link onClick={() => balanceRefetch()}>try again?</Link>
                </Typography>
              ) : (
                <Typography fontFamily={"monospace"} fontSize={32}>
                  {balanceData.available
                    ? `${balanceData.pending[0].currency.toUpperCase()} ${DineroFactory(
                        {
                          amount: balanceData.pending[0].amount,
                        }
                      ).toFormat("0,0.00")}`
                    : "-"}
                </Typography>
              )}
            </>
          )}
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <Card variant="outlined">
          {(isLoading || isFetching) && !error ? (
            <CircularProgress />
          ) : (
            <>
              <Typography color="success" level="title-md">
                Cleared funds{" "}
                <Link
                  startDecorator={<Refresh />}
                  onClick={() => balanceRefetch()}
                ></Link>
              </Typography>
              {error ? (
                <Typography color="warning">
                  Could not fetch balance data,{" "}
                  <Link onClick={() => balanceRefetch()}>try again?</Link>
                </Typography>
              ) : (
                <Typography fontFamily={"monospace"} fontSize={32}>
                  {balanceData.available
                    ? `${balanceData.available[0].currency.toUpperCase()} ${DineroFactory(
                        {
                          amount: balanceData.available[0].amount,
                        }
                      ).toFormat("0,0.00")}`
                    : "-"}
                </Typography>
              )}
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
