import { Autocomplete } from "@mui/joy";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function SkillLevelSelector(props) {
  const { setValue } = useFormContext();
  const [availableSkillLevels] = useState([
    "Beginner",
    "Intermediate",
    "Advanced",
  ]);

  return (
    <>
      <Autocomplete
        id={props.name}
        options={availableSkillLevels}
        multiple
        defaultValue={props.value}
        onChange={(e, values) => {
          setValue(props.name, values);
        }}
      />
    </>
  );
}
