import { Autocomplete } from "@mui/joy";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function RetreatTypeSelector(props) {
  const { setValue, formState, register } = useFormContext();
  const [availableRetreatTypes] = useState([
    "Boutique",
    "Luxury",
    "Meditation",
    "Mindfulness",
    "Restorative",
    "Silent",
    "Sound Bath",
    "Spa",
    "Ayurveda Detox",
    "Juice Fast Detox",
    "Raw Food Detox",
    "Couples",
    "Group",
    "Marriage",
    "Mothers & Daughters",
    "Private",
    "Solo travellers",
    "Women's only",
    "Creative writing",
    "Creative thinking",
    "Photography",
    "Detox",
    "Fasting",
    "Healthy Cooking",
    "Nutrition",
    "Raw Food",
    "Vegan",
    "Vegetarian",
    "Adventure",
    "Business coaching",
    "Life Coaching reterats",
    "Spiritual",
    "Transformation",
    "Wellness",
    "Health",
    "Ayahuasca",
    "Ayurveda",
    "Cancer recovery",
    "Emotional healing",
    "Grief",
    "Healing",
    "Medical",
    "Mental health",
    "Menopause",
    "Psychedelic",
    "Therapy",
    "Sleep",
    "Cycling",
    "Cold therapy",
    "Dance",
    "Fitness",
    "Horse riding",
    "Pilates",
    "Running",
    "Walking",
    "Weight loss",
    "Yoga",
  ]);

  if (props.required) {
    register(props.name, { required: true });
  }

  return (
    <>
      <Autocomplete
        multiple
        placeholder="Select a retreat type"
        options={availableRetreatTypes}
        defaultValue={props.value}
        onChange={(e, values) => {
          console.log("values", values);
          setValue(props.name, values);
        }}
        color={
          formState.errors[props.name]?.type === "required"
            ? "warning"
            : "neutral"
        }
      />
    </>
  );
}
