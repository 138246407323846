/* eslint-disable no-unused-vars */
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { Building3, Clock, Star1 } from "iconsax-react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useGetWizardsQuery } from "services/osare";

export default function WizardSelectModal({ isOpen, setIsListingWizardOpen }) {
  const { data: wizardsData, isLoading, isFetching } = useGetWizardsQuery({});
  const navigate = useNavigate();

  return (
    <Modal open={isOpen} onClose={() => setIsListingWizardOpen(false)}>
      <ModalDialog>
        <ModalClose />

        {isLoading || isFetching ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              overflow: "scroll",
              mx: "calc(-1 * var(--ModalDialog-padding))",
              px: "var(--ModalDialog-padding)",
            }}
          >
            <DialogTitle>Select a Wizard</DialogTitle>
            <DialogContent>
              <List>
                {wizardsData?.results?.map((wizard) => (
                  <ListItem key={wizard._id}>
                    <ListItemButton
                      onClick={() => navigate(`/dash/wizard/${wizard._id}`)}
                    >
                      <ListItemContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography level="body-lg">
                              Wizard created on{" "}
                              {wizard.createdAt
                                ? wizard.createdAt
                                : "(no date)"}
                            </Typography>
                            <Typography startDecorator={<Building3 />}>
                              {wizard.accommodationTitle ??
                                "(no accommodation name yet)"}
                            </Typography>
                            <Typography gutterBottom startDecorator={<Star1 />}>
                              {wizard.listingTitle ?? "(no listing name yet)"}
                            </Typography>
                            {wizard.updatedAt && (
                              <Typography
                                marginLeft={0.5}
                                level="body-xs"
                                startDecorator={<Clock size={16} />}
                              >
                                Last updated:{" "}
                                {DateTime.fromSeconds(
                                  wizard.updatedAt / 1000
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </ListItemContent>
                      <KeyboardArrowRight />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
}
