/* eslint-disable no-unused-vars */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import { Add } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Link,
  Sheet,
  Stack,
  Table,
} from "@mui/joy";
import { useGetEventsQuery } from "services/osare";
import PreCreateModal from "pages/Dash/Components/PreCreateModal/PreCreateModal";
import { useDispatch, useSelector } from "react-redux";
import { triggerSuccessSnackbar } from "services/snackbars";

export default function DashEventsList() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = useLocation();
  const { data, refetch, isLoading } = useGetEventsQuery("");
  const dispatch = useDispatch();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (location.state && location.state.saved === true) {
      dispatch(triggerSuccessSnackbar({ open: true, message: "Saved" }));
    }
  }, [location.state]);

  const EventListComponent = () => (
    <Stack alignItems={"flex-start"} marginTop={2}>
      <PreCreateModal type="event" setOpen={setOpen} open={open} />
      <Button onClick={() => setOpen(true)} startDecorator={<Add />}>
        Add new event
      </Button>

      {isLoading ? (
        <Grid container marginTop={3}>
          <Alert color="primary" startDecorator={<CircularProgress />}>
            Just a moment...
          </Alert>
        </Grid>
      ) : null}

      {data ? (
        <Sheet>
          <Table>
            <thead>
              <tr>
                <th>Event Name</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((d) => (
                <tr key={d._id}>
                  <td>
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate(`edit?id=${d._id}`)}
                      underline="hover"
                    >
                      {d.name}
                    </Link>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      ) : null}
    </Stack>
  );

  return (
    <>
      {currentPath.pathname === "/dash/events" ? (
        <EventListComponent />
      ) : (
        <Outlet />
      )}
    </>
  );
}
