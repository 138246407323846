import { Error } from "@mui/icons-material";
import { Alert, Link, Typography } from "@mui/joy";
import React from "react";

export default function DataErrorComponent(props) {
  return (
    <Alert startDecorator={<Error />} color="danger">
      There was a problem loading the data.{" "}
      <Typography level="body-sm">
        <Link onClick={() => props.retryTrigger()}>Try again?</Link>
      </Typography>
    </Alert>
  );
}
