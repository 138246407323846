/* eslint-disable no-unused-vars */

import { HelpOutline } from "@mui/icons-material";
import {
  Button,
  Card,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
} from "@mui/joy";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useCreatePapertrailMutation } from "services/osare";

export default function PapertrailList(props) {
  const [contentState, setContentState] = useState({
    blocks: [],
    entityMap: {},
  });
  const [sendToCustomer, setSendToCustomer] = useState(false);
  const [openCloseObject, setOpenCloseObject] = useState({});
  const [reversedArray, setReversedArray] = useState([]);
  const [createPapertrail] = useCreatePapertrailMutation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    console.log("props.papertrailData", props.papertrailData);
    if (!_.isEmpty(props.papertrailData)) {
      let reversed = [];
      reversed = [...props.papertrailData];
      reversed.reverse();

      console.log("reversed:", reversed);

      setReversedArray([...reversed]);

      const openCloseObject = {};
      reversed.forEach((element) => {
        openCloseObject[element._id] = false;
      });

      setOpenCloseObject(openCloseObject);
      console.log("reversedArray", reversedArray);
      console.log("openCloseObject", openCloseObject);
    }
  }, [props.papertrailData]);

  const toggleDrawer = (p) => {
    const modifiedOpenClose = { ...openCloseObject };
    modifiedOpenClose[p._id] = !modifiedOpenClose[p._id];

    setOpenCloseObject({ ...modifiedOpenClose });
  };

  const addPapertrailItem = () => {
    if (title === "") {
      alert("Please specify a title for this papertrail item.");
      return;
    }

    createPapertrail({
      type: "orderNote",
      title,
      merchantFirebaseUserId: props.orderData.merchantFirebaseUserId,
      customerFirebaseUserId: props.orderData.customerFirebaseUserId,
      description: contentState,
      exposeToCustomer: sendToCustomer,
      metadata: {
        orderId: props.orderData._id,
        listingId: props.orderData.listingId,
      },
    });

    if (props.refetchPapertrail) {
      props.refetchPapertrail();
    }

    setTitle("");
    setContentState({
      blocks: [],
      entityMap: {},
    });
    setSendToCustomer(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Input
            size={"lg"}
            placeholder="Papertrail Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <Card>
            <Editor
              initialContentState={{ blocks: [], entityMap: {} }}
              onContentStateChange={(cs) => {
                setContentState(cs);
              }}
              toolbar={{
                options: ["list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
            />
          </Card>
        </Grid>

        <Grid xs={12}>
          <FormLabel>
            <Switch
              checked={sendToCustomer}
              value={true}
              onChange={(e) => setSendToCustomer(e.target.checked)}
            />
            <Typography marginLeft={1}>
              Also send this update to the customer?
            </Typography>
          </FormLabel>
        </Grid>
        <Grid xs={12}>
          <Button variant="outlined" onClick={() => addPapertrailItem()}>
            Add papertrail item {sendToCustomer && "and send to customer"}
          </Button>
        </Grid>

        <Grid xs={12}>
          <Typography level="title-lg">Existing Papertrails</Typography>
        </Grid>

        {reversedArray.length === 0 && (
          <Grid xs={12}>
            <Typography startDecorator={<HelpOutline />}>
              No papertrail items currently exist for this order. You can add a
              new one above.
            </Typography>
          </Grid>
        )}

        {reversedArray.length > 0 && (
          <List>
            {reversedArray.map((p) => {
              return (
                <ListItem key={`list-${p._id}`}>
                  <ListItemContent>
                    <Typography level="title-sm">
                      {DateTime.fromMillis(p.createdAt).toLocaleString(
                        DateTime.DATETIME_MED_WITH_WEEKDAY
                      )}
                    </Typography>
                    <Typography level="title-md" noWrap>
                      {p.title}
                    </Typography>
                    <Typography level="body-sm">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(p.description),
                        }}
                      />
                    </Typography>
                  </ListItemContent>
                </ListItem>
              );
            })}
          </List>
        )}
      </Grid>
    </>
  );
}
