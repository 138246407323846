/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  CircularProgress,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useEffect } from "react";
import { useLazyCmsDocumentByIdQuery } from "services/osare";

export default function DocumentViewerModal(props) {
  const [lazyDocumentData, lazyDocumentDataResponse] =
    useLazyCmsDocumentByIdQuery();
  const [documentData, setDocumentData] = React.useState(null);

  useEffect(() => {
    if (props.documentId && props.open) {
      lazyDocumentData({
        id: props.documentId,
      });
    }
  }, [props.documentId]);

  useEffect(() => {
    if (lazyDocumentDataResponse.isSuccess) {
      let documentData = lazyDocumentDataResponse.data?.results?.document.body;
      documentData = documentData.replace(
        "%%INTERCEPT_NAME%%",
        props.accountData.company.name
      );

      setDocumentData(documentData);
    }
  }, [lazyDocumentDataResponse]);

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}></Stack>
      <Modal open={props.open} onClose={() => props.setOpenFn(false)}>
        <ModalOverflow>
          <ModalDialog
            aria-labelledby="modal-dialog-overflow"
            layout={"center"}
          >
            <ModalClose />
            {(lazyDocumentDataResponse.isLoading ||
              lazyDocumentDataResponse.isFetching) && (
              <>
                <Typography>Just a moment...</Typography>
                <Box textAlign={"center"}>
                  <CircularProgress />
                </Box>
              </>
            )}

            {!lazyDocumentDataResponse.isError ? (
              <>
                <Typography id="modal-dialog-overflow" level="h2">
                  {lazyDocumentDataResponse.data?.results?.document.title}
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentData,
                  }}
                />
              </>
            ) : (
              <>
                <Typography id="modal-dialog-overflow" level="h2">
                  Sorry...
                </Typography>
                <Alert color="danger">No document found!</Alert>
              </>
            )}
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </React.Fragment>
  );
}
