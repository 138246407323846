import { Box, Button, Typography } from "@mui/joy";
import PossibleEarningsCalculator from "pages/Dash/Components/PossibleEarningsCalculator/PossibleEarningsCalculator";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetConfigQuery, useGetWizardsQuery } from "services/osare";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardEstimatedEarnings() {
  const { t } = useTranslation();
  const { wizardId } = useParams();
  const navigate = useNavigate();
  const formMethods = useForm();
  const { isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });

  useEffect(() => {
    console.log("wizardData", wizardData);
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const onSubmit = () => {
    navigate(`/dash/wizard/${wizardId}/pricing/policies`);
  };

  return (
    <>
      {!isConfigLoading && !isWizardDataLoading ? (
        <>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Typography level="title-lg">
                {t("dashboard.listings.upsert.possibleEarnings.title")}
              </Typography>
              <Typography gutterBottom level="body-md">
                {t("dashboard.listings.upsert.possibleEarnings.description")}
              </Typography>
              <PossibleEarningsCalculator
                accommodationIds={[wizardData.results[0].accommodationId]}
                listingId={wizardData.results[0].listingId}
              />

              <Box mt={3}>
                <Button type="submit">Continue</Button>
              </Box>
            </form>
          </FormProvider>
        </>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
