/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import PolicySelector from "pages/Dash/Components/PolicySelector/PolicySelector";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetListingByIdQuery,
  useUpdateListingMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardPricingPolicies() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getListingData, listingResponse] = useLazyGetListingByIdQuery();
  const [updateListingData, updateListingResponse] = useUpdateListingMutation();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data) => {
    console.log("Form data", data);

    if (data.policyId === "TBD") {
      alert("Please select a policy first");
      return;
    }

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (!data.policyId) {
      alert("Please select a policy first");
      return;
    }

    setSaving(true);

    await updateListingData({
      id: wizardData.results[0].listingId,
      updateData: {
        policyId: data.policyId,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);

        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );

        navigate(`/dash/wizard/${wizardId}/finished`);
      })
      .catch((error) => {
        console.error("Error updating listing", error);
        setSaving(false);
        alert("Error updating listing, please try again.");
      });
  };

  useEffect(() => {
    if (listingResponse.isSuccess && listingResponse.data) {
      console.log("Listing data", listingResponse.data);

      formMethods.setValue(
        "policyId",
        listingResponse.data.results[0].policyId
      );

      // Object.keys(listingResponse.data.results[0]).forEach((key) => {
      //   formMethods.setValue(key, listingResponse.data.results[0][key]);
      // });
    }
  }, [listingResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getListingData(wizardData.results[0].listingId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      listingResponse.isSuccess &&
      !listingResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Box marginBottom={3}>
              <Typography level="title-lg">
                {t("dashboard.listings.upsert.paymentsAndRefundsPolicy.title")}
              </Typography>
              <Typography gutterBottom level="body-md">
                {t(
                  "dashboard.listings.upsert.paymentsAndRefundsPolicy.description"
                )}
              </Typography>

              <PolicySelector
                listingData={
                  listingResponse.isSuccess && listingResponse.data.results[0]
                }
              />
            </Box>

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
