import * as React from "react";

import { FormControl, FormHelperText, Radio, RadioGroup } from "@mui/joy";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function RequestToBookToggle(props) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [radioValue, setRadioValue] = React.useState(
    props.value ? props.value.toString() : "false"
  );

  return (
    <FormControl>
      <RadioGroup
        onChange={(e) => {
          setValue(props.name, e.target.value);
          setRadioValue(e.target.value);
        }}
        defaultValue={"false"}
        name={props.name}
        value={radioValue}
      >
        <Radio
          value={"false"}
          label={t("dashboard.listings.upsert.requestToBookMode.instantTitle")}
          slotProps={{ input: { "aria-describedby": "approved-helper-text" } }}
        />
        <FormHelperText id="approved-helper-text">
          {t("dashboard.listings.upsert.requestToBookMode.instantDescription")}
        </FormHelperText>

        <Radio
          value={"true"}
          label={t("dashboard.listings.upsert.requestToBookMode.requestTitle")}
          slotProps={{ input: { "aria-describedby": "approve-helper-text" } }}
        />
        <FormHelperText id="approve-helper-text">
          {t("dashboard.listings.upsert.requestToBookMode.requestDescription")}
        </FormHelperText>
      </RadioGroup>
    </FormControl>
  );
}
