import { Check } from "@mui/icons-material";
import { Avatar, Box, Chip, CircularProgress } from "@mui/joy";
import { useFormContext } from "react-hook-form";
import { useGetMediaQuery, useGetStaffQuery } from "services/osare";

export default function StaffChooser(props) {
  const formMethods = useFormContext();
  const { data: staffData, isLoading: staffIsLoading } = useGetStaffQuery({});
  const currentValues = formMethods.getValues(props.name) || [];

  formMethods.watch(props.name);

  const onClickChip = (staffId) => {
    const currentValues = formMethods.getValues(props.name) || [];
    if (currentValues.includes(staffId)) {
      formMethods.setValue(
        props.name,
        currentValues.filter((id) => id !== staffId)
      );
    } else {
      formMethods.setValue(props.name, [...currentValues, staffId]);
    }
  };

  const StaffLoading = () => {
    return staffIsLoading && <div>Loading...</div>;
  };

  const StaffAvatar = (props) => {
    const {
      data: mediaData,
      isLoading: isMediaLoading,
      isFetching: isMediaFetching,
    } = useGetMediaQuery({
      collection: "staff",
      collectionId: props.staffId,
    });

    return isMediaLoading || isMediaFetching ? (
      <CircularProgress size="sm" />
    ) : (
      <Avatar
        size="lg"
        alt="Staff Avatar"
        src={mediaData?.results[0] ? mediaData.results[0].signedUrl : ""}
      />
    );
  };

  return (
    <>
      <StaffLoading />

      {staffData?.results.map((staff) => (
        <Box my={2} key={staff._id}>
          <Chip
            onClick={() => onClickChip(staff._id)}
            size="lg"
            color={currentValues.includes(staff._id) ? "success" : "primary"}
            variant={
              currentValues.includes(staff._id) ? "outlined" : "outlined"
            }
            startDecorator={<StaffAvatar staffId={staff._id} />}
            endDecorator={
              currentValues.includes(staff._id) && <Check fontSize="medium" />
            }
          >{`${staff.firstName} ${staff.lastName} - ${staff.role}`}</Chip>
        </Box>
      ))}
    </>
  );
}
