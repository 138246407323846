/* eslint-disable no-unused-vars */
// core
import { useEffect, useState } from "react";

// libraries
import { Backdrop } from "@mui/material";

// Styles
import "react-calendar/dist/Calendar.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// redux / slices
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import isEventComponentEnabled from "helpers/isEventComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import DietarySelector from "pages/Dash/Components/DietarySelector/DietarySelector";
import DrinksSelector from "pages/Dash/Components/DrinksSelector/DrinksSelector";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import ItineraryBuilder from "pages/Dash/Components/ItineraryBuilder/ItineraryBuilder";
import ListBuilder from "pages/Dash/Components/ListBuilder/ListBuilder";
import LocationSelector from "pages/Dash/Components/LocationSelector/LocationSelector";
import MealsSelector from "pages/Dash/Components/MealsSelector/MealsSelector";
import RetreatTypeSelector from "pages/Dash/Components/RetreatTypeSelector/RetreatTypeSeletor";
import SkillLevelSelector from "pages/Dash/Components/SkillLevelSelector/SkillLevelSelector";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useLazyGetEventByIdQuery,
  useUpdateEventMutation,
} from "services/osare";

export default function DashEventsUpsert() {
  const { t } = useTranslation();
  const formMethods = useForm();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [searchParams] = useSearchParams();
  const [editMode] = useState(!!searchParams.get("id"));
  const [updateEvent, updateEventResult] = useUpdateEventMutation();
  const [trigger, result] = useLazyGetEventByIdQuery();
  const [eventData, setEventData] = useState({});
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  // Edit Mode save
  useEffect(() => {
    if (updateEventResult.status === "fulfilled") {
      setSaving(false);
      setSaved(true);

      navigate("/dash/events", {
        state: {
          saved: true,
        },
      });
    }

    if (updateEventResult.status === "rejected") {
      setSaving(false);
      alert("Error saving!");
    }
  }, [updateEventResult]);

  // Edit Mode
  useEffect(() => {
    console.log("Search Params", searchParams.get("id"));
    console.log("Edit Mode?", editMode);

    if (editMode && result.status !== "fulfilled") {
      console.log("Triggering single record fetch...");
      trigger(searchParams.get("id"));
    }

    if (result.status === "fulfilled") {
      console.log("fetched data!", result.data.results[0]);
      setEventData(result.data.results[0]);

      Object.keys(result.data.results[0]).forEach((key) => {
        formMethods.setValue(key, result.data.results[0][key]);
      });
    }
  }, [editMode, result.data, result.status, searchParams, trigger]);

  const onSubmit = (values) => {
    setSaving(true);

    console.log(values);
    console.log(Object.keys(values));

    values.geoJson = {
      type: "Point",
      coordinates: [values.location.geometry.lat, values.location.geometry.lng],
    };

    if (editMode) {
      console.log("Updating...");

      updateEvent({
        id: searchParams.get("id"),
        updateData: values,
      });
    }
  };

  formMethods.watch("minimumAge");
  formMethods.watch("maximumAge");
  // formMethods.watch("itineraryDayByDay");

  return (
    <Grid container spacing={2}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editMode && !eventData.name}
      >
        <CircularProgress />
      </Backdrop>
      {(editMode && eventData.name) || !editMode ? (
        <>
          <Grid md={3}></Grid>
          <Grid xs={12} md={6}>
            <Box marginBottom={5} />
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Card variant="outlined">
                      <Typography gutterBottom level="title-lg">
                        {t("dashboard.events.upsert.intro.title")}
                      </Typography>

                      <Typography gutterBottom level="body-lg">
                        {t("dashboard.events.upsert.intro.description")}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>

                {isEventComponentEnabled(configData, "name") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.name.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.name.description")}
                    </Typography>

                    <Input
                      fullWidth
                      name="name"
                      placeholder="Name of event"
                      variant="outlined"
                      {...formMethods.register("name")}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "tagline") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.tagline.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.tagline.description")}
                    </Typography>

                    <Input
                      fullWidth
                      name="tagline"
                      placeholder="Tagline of event"
                      variant="outlined"
                      {...formMethods.register("tagline")}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "introduction") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.introduction.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.introduction.description")}
                    </Typography>

                    <Input
                      fullwidth
                      name="introduction"
                      placeholder="Introduction"
                      variant="outlined"
                      {...formMethods.register("introduction")}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "highlights") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.highlights.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.highlights.description")}
                    </Typography>

                    <DashEditor
                      name="highlights"
                      contentState={eventData.highlights}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "expectedExperience") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.expectedExperience.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.expectedExperience.description"
                      )}
                    </Typography>

                    <DashEditor
                      name="expectedExperience"
                      contentState={eventData.expectedExperience}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "photos") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.photos.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.photos.description")}
                    </Typography>

                    <GalleryManager
                      collection="events"
                      collectionId={searchParams.get("id")}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "retreatCategory") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.eventType.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.eventType.description")}
                    </Typography>

                    <RetreatTypeSelector
                      name="categories"
                      value={eventData.categories}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "map") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.map.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.map.description")}
                    </Typography>

                    <LocationSelector
                      name="location"
                      value={eventData.location}
                    />
                  </Grid>
                )}

                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="large"
                        value={props.values.locationObscured}
                        name="locationObscured"
                        onChange={props.handleChange}
                      />
                    }
                    label="Hide exact location until booked?"
                  />
                </FormGroup> */}

                {/* Arrival information and directions */}

                <Grid xs={12}>
                  <Card variant="outlined">
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.arrivalInformation.title")}
                    </Typography>

                    <Typography gutterBottom level="body-lg">
                      {t(
                        "dashboard.events.upsert.arrivalInformation.description"
                      )}
                    </Typography>
                  </Card>
                </Grid>

                {isEventComponentEnabled(configData, "arrivalInformation") && (
                  <Grid xs={12}>
                    {/* <Typography gutterBottom level="title-lg">
                    Check-in and check-out time
                  </Typography>

                  <Typography gutterBottom level="body-md">
                    Please select the check-in and check-out time. These are the
                    times that you expect your event atendees to arrive and
                    leave by.
                  </Typography> */}

                    <Grid container>
                      <Grid xs={6}>
                        <FormControl>
                          <FormHelperText>Check-in Time</FormHelperText>
                          <Input
                            type="time"
                            name="checkInTime"
                            {...formMethods.register("checkInTime")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl>
                          <FormHelperText>Check-out Time</FormHelperText>
                          <Input
                            type="time"
                            name="checkOutTime"
                            {...formMethods.register("checkOutTime")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {/* Itinerary and Schedule */}
                <Grid xs={12}>
                  <Card variant="outlined">
                    <Typography gutterBottom level="title-lg">
                      {t(
                        "dashboard.events.upsert.itineraryScheduleIntro.title"
                      )}
                    </Typography>

                    <Typography gutterBottom level="body-lg">
                      {t(
                        "dashboard.events.upsert.itineraryScheduleIntro.description"
                      )}
                    </Typography>
                  </Card>
                </Grid>

                {isEventComponentEnabled(configData, "eventDuration") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.eventDuration.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.eventDuration.description")}
                    </Typography>

                    <Input
                      size="lg"
                      sx={{ width: 100 }}
                      name="eventDaysLength"
                      type="number"
                      slotProps={{ input: { min: 1 } }}
                      label="Length of event in days"
                      {...formMethods.register("eventDaysLength", {
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "daysWithInstruction") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.daysWithInstruction.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.daysWithInstruction.description",
                        {
                          daysWithInstruction: formMethods.getValues(
                            "daysWithInstruction"
                          ),
                        }
                      )}
                    </Typography>

                    <Input
                      size="lg"
                      sx={{ width: 100 }}
                      slotProps={{ input: { min: 0 } }}
                      type="number"
                      name="daysWithInstruction"
                      label="Length of in days that include instruction"
                      {...formMethods.register("daysWithInstruction", {
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "minimumGroupSize") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.minimumGroupSize.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.minimumGroupSize.description"
                      )}
                    </Typography>

                    <Input
                      size="lg"
                      sx={{ width: 100 }}
                      type="number"
                      slotProps={{ input: { min: 1 } }}
                      name="minimumGroupSize"
                      label="Minimum"
                      {...formMethods.register("minimumGroupSize", {
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "maximumGroupSize") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t(
                        "dashboard.events.upsert.maximumGroupSize.description"
                      )}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.maximumGroupSize.description"
                      )}
                    </Typography>

                    <Input
                      size="lg"
                      sx={{ width: 100 }}
                      type="number"
                      slotProps={{ input: { min: 1 } }}
                      name="maximumGroupSize"
                      label="Maximum"
                      {...formMethods.register("maximumGroupSize", {
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "itinerary") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.itinerary.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.itinerary.description")}
                    </Typography>

                    <Typography marginY={2} gutterBottom level="title-md">
                      {t("dashboard.events.upsert.itinerary.overallInputTitle")}
                    </Typography>

                    <DashEditor
                      name="itineraryOverview"
                      contentState={eventData.itineraryOverview}
                    />

                    <Typography marginY={2} gutterBottom level="title-md">
                      {t(
                        "dashboard.events.upsert.itinerary.dayByDayInputTitle"
                      )}
                    </Typography>

                    <ItineraryBuilder
                      value={formMethods.getValues("itineraryDayByDay")}
                      name="itineraryDayByDay"
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "whatsIncluded") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.whatsIncluded.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.whatsIncluded.description")}
                    </Typography>

                    <ListBuilder
                      name="specificallyIncluded"
                      value={eventData.specificallyIncluded}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "whatsNotIncluded") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.whatsNotIncluded.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.whatsNotIncluded.description"
                      )}
                    </Typography>

                    <ListBuilder
                      name="specificallyExcluded"
                      value={eventData.specificallyExcluded}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "optionalExtras") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.optionalExtras.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.optionalExtras.description")}
                    </Typography>

                    <DashEditor
                      name="thingsToDoPaid"
                      contentState={eventData.thingsToDoPaid}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "spaTreatments") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.spaTreatments.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.spaTreatments.description")}
                    </Typography>

                    <DashEditor
                      name="spaTreatments"
                      contentState={eventData.spaTreatments}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "knowBeforeYouGo") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.knowBeforeYouGo.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.knowBeforeYouGo.description")}
                    </Typography>

                    <DashEditor
                      name="preTravelAdvisory"
                      contentState={eventData.preTravelAdvisory}
                    />
                  </Grid>
                )}

                {/* Guest Requirements */}

                {isEventComponentEnabled(
                  configData,
                  "instructionLanguages"
                ) && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.instructionLanguages.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.instructionLanguages.description"
                      )}
                    </Typography>

                    <Autocomplete
                      multiple
                      name="spokenLanguages"
                      options={["English", "French", "German"]}
                      label="Language"
                      onChange={(e, value) =>
                        formMethods.setValue("spokenLanguages", value)
                      }
                      defaultValue={eventData.spokenLanguages}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "skillLevel") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.skillLevel.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.skillLevel.description")}
                    </Typography>

                    <SkillLevelSelector
                      name="skillLevels"
                      value={eventData.skillLevels}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "minimumMaximumAges") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.minimumMaximumAges.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.minimumMaximumAges.description"
                      )}
                    </Typography>

                    <Grid container>
                      <Grid xs={6}>
                        <FormControl>
                          <FormHelperText>
                            {t(
                              "dashboard.events.upsert.minimumMaximumAges.minimumInputLabel"
                            )}
                          </FormHelperText>
                          <Input
                            value={formMethods.getValues("minimumAge")}
                            type="number"
                            slotProps={{ input: { min: 1 } }}
                            name="minimumAge"
                            label="Minimum"
                            {...formMethods.register("minimumAge", {
                              valueAsNumber: true,
                            })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl>
                          <FormHelperText>
                            {t(
                              "dashboard.events.upsert.minimumMaximumAges.maximumInputLabel"
                            )}
                          </FormHelperText>
                          <Input
                            value={formMethods.getValues("maximumAge")}
                            type="number"
                            slotProps={{ input: { min: 1 } }}
                            name="maximumAge"
                            label="Maximum"
                            {...formMethods.register("maximumAge", {
                              valueAsNumber: true,
                            })}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {formMethods.getValues("minimumAge") < 18 && (
                  <>
                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        Can children participate?
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        If the minimum age above is below 18, please tell us up
                        to what age they must be accompanied by an adult
                      </Typography>

                      <Input
                        size="lg"
                        sx={{ width: 100 }}
                        type="number"
                        slotProps={{ input: { min: 1 } }}
                        name="accompaniedByAdultAge"
                        label="Minimum Age"
                        {...formMethods.register("accompaniedByAdultAge")}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <Typography gutterBottom level="title-lg">
                        Are children allowed as non-participating guests?
                      </Typography>

                      <Typography gutterBottom level="body-md">
                        Please let us know if children are allowed to this event
                        as non-participating guests.
                      </Typography>

                      <Checkbox
                        value={true}
                        checked={eventData.nonParticipatingChildrenAllowed}
                        name="nonParticipatingChildrenAllowed"
                        label={
                          "Children are allowed as non-participating guests"
                        }
                        {...formMethods.register(
                          "nonParticipatingChildrenAllowed"
                        )}
                      />
                    </Grid>
                  </>
                )}

                {isEventComponentEnabled(
                  configData,
                  "overallFoodDescription"
                ) && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t(
                        "dashboard.events.upsert.overallFoodDescription.title"
                      )}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.overallFoodDescription.description"
                      )}
                    </Typography>

                    <DashEditor
                      name="overallFoodDescription"
                      contentState={eventData.overallFoodDescription}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "foodSpecifics") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.foodSpecifics.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.foodSpecifics.description")}
                    </Typography>

                    <DashEditor
                      name="additionalFoodDescription"
                      contentState={eventData.additionalFoodDescription}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "includedMeals") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.includedMeals.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.includedMeals.description")}
                    </Typography>

                    <MealsSelector
                      name="includedMeals"
                      value={eventData.includedMeals}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(
                  configData,
                  "includedFoodDietaryTypes"
                ) && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t(
                        "dashboard.events.upsert.includedFoodDietaryTypes.title"
                      )}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t(
                        "dashboard.events.upsert.includedFoodDietaryTypes.description"
                      )}
                    </Typography>

                    <DietarySelector
                      name="dietarySupport"
                      value={eventData.dietarySupport}
                    />
                  </Grid>
                )}

                {isEventComponentEnabled(configData, "includedDrinks") && (
                  <Grid xs={12}>
                    <Typography gutterBottom level="title-lg">
                      {t("dashboard.events.upsert.includedDrinks.title")}
                    </Typography>

                    <Typography gutterBottom level="body-md">
                      {t("dashboard.events.upsert.includedDrinks.description")}
                    </Typography>

                    <DrinksSelector
                      name="includedDrinks"
                      value={eventData.includedDrinks}
                    />
                  </Grid>
                )}

                <Box margin={2} />

                <Grid xs={12}>
                  <Card>
                    <Button
                      disabled={saving}
                      startDecorator={saving ? <CircularProgress /> : null}
                      variant="soft"
                      type="submit"
                    >
                      {saving ? "Saving..." : "Save"}
                    </Button>
                  </Card>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
          <Grid md={"3"}></Grid>
        </>
      ) : null}
    </Grid>
  );
}
