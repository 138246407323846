import { KingBed } from "@mui/icons-material";
import { Chip } from "@mui/joy";
import _ from "lodash";

export default function SleepingArrangmentsComponent(props) {
  // Workout sleeping arrangements UI
  const sleepingArrangements = props.sleepingArrangements.map((sa) => {
    return (
      <Chip
        size="sm"
        startDecorator={<KingBed />}
        key={`sa-${sa.name[0].text}-${_.uniqueId()}`}
      >
        {sa.name[0].text} - Sleeps {sa.occupant_count}
      </Chip>
    );
  });

  return sleepingArrangements;
}
