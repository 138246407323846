/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import { ProfileDelete } from "iconsax-react";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import GalleryManager from "pages/Dash/Components/GalleryManager/GalleryManager";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteStaffMutation,
  useGetStaffQuery,
  useUpdateStaffMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";

export default function DashStaffEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const methods = useForm();
  const {
    data: staffData,
    isLoading: isStaffLoading,
    isFetching: isStaffFetching,
    error: staffError,
    isError: isStaffError,
    isSuccess: isStaffSuccess,
  } = useGetStaffQuery(
    {
      query: {
        id: searchParams.get("id"),
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [updateStaff, updateStaffResult] = useUpdateStaffMutation();
  const [deleteStaff, deleteStaffResult] = useDeleteStaffMutation();

  const onSubmit = (data) => {
    console.log(data);
    updateStaff({
      updateData: data,
      id: searchParams.get("id"),
    });
  };

  const askToDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this staff member? This action cannot be undone..."
      )
    ) {
      // eslint-disable-next-line no-restricted-globals
      // navigate(`/dash/staff/delete?id=${searchParams.get("id")}`);
      const r = await deleteStaff({ id: searchParams.get("id") }).unwrap();
      if (r instanceof Error) {
        alert(
          "Sorry, an error occurred while deleting the staff member. Please try again."
        );
      } else {
        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Staff member deleted!",
          })
        );
        navigate(`/dash/staff`);
      }
    }
  };

  useEffect(() => {
    if (updateStaffResult.isSuccess) {
      console.log("Staff Updated");
      dispatch(
        triggerSuccessSnackbar({
          open: true,
          message: "Saved!",
        })
      );

      navigate("/dash/staff");
    }
  }, [updateStaffResult]);

  useEffect(() => {
    if (isStaffSuccess && staffData.results.length > 0) {
      Object.entries(staffData.results[0]).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
    }
  }, [staffData]);

  return (
    <>
      <Box>
        <Typography
          marginTop={5}
          marginBottom={2}
          level="h3"
          textAlign={"center"}
        >
          Add an Organiser or Teacher
        </Typography>

        {isStaffLoading ? (
          <Box
            mt={5}
            mb={5}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid xs={0} sm={2}></Grid>
            <Grid xs={12} sm={8}>
              <Card sx={{ marginTop: 3 }}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography>First Name</Typography>
                    <Input
                      {...methods.register("firstName")}
                      required
                      placeholder="John"
                    />

                    <Box m={2} />

                    <Typography>Last Name</Typography>
                    <Input
                      {...methods.register("lastName")}
                      required
                      placeholder="Smith"
                      color="neutral"
                    />

                    <Box m={2} />

                    <Typography>Role</Typography>
                    <Input
                      {...methods.register("role")}
                      required
                      placeholder="Instructor"
                    />

                    <Box m={2} />

                    <Typography>Biography</Typography>
                    <DashEditor
                      name="bio"
                      required
                      contentState={staffData?.results[0].bio}
                    />

                    <Box m={2} />

                    <Typography>Profile Photo:</Typography>
                    <GalleryManager
                      mediaType="single"
                      collection={"staff"}
                      collectionId={staffData?.results[0]._id}
                    />

                    <Box m={2} />

                    <Button type="submit">Save</Button>
                    <Button
                      onClick={() => navigate("/dash/staff")}
                      variant="plain"
                    >
                      Cancel
                    </Button>
                  </form>
                </FormProvider>
              </Card>
              <Box
                m={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => askToDelete()}
                  variant="plain"
                  color="danger"
                  startDecorator={<ProfileDelete size={20} />}
                >
                  Delete this staff member?
                </Button>
              </Box>
            </Grid>
            <Grid xs={0} sm={2}></Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
