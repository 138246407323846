import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import snackbarsReducer from "services/snackbars";

import { osareApi } from "services/osare";

export const store = configureStore({
  reducer: {
    [osareApi.reducerPath]: osareApi.reducer,
    snackbars: snackbarsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(osareApi.middleware),
});

setupListeners(store.dispatch);
