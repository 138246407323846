/* eslint-disable no-unused-vars */
import { FiberManualRecord } from "@mui/icons-material";
import { Card, CircularProgress, Link, Table, Typography } from "@mui/joy";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useGetListingsQuery, useGetOrdersQuery } from "services/osare";

export default function OrdersTable() {
  const { data: ordersData, isLoading, isFetching } = useGetOrdersQuery();
  const { data: listingData, isFetching: isListingDataFetching } =
    useGetListingsQuery();

  const navigate = useNavigate();

  const StatusToColor = (order) => {
    if (order.isCancelled) {
      return "danger";
    } else {
      if (order.isAwaitingApproval) {
        return "warning";
      } else {
        return "success";
      }
    }
  };

  const StatusToText = (order) => {
    if (order.isCancelled) {
      return `This order has been ${order.isDenied ? "denied" : "cancelled"} ${
        order.isCancelledByMerchant ? "by you" : "by the customer"
      }`;
    } else {
      if (order.isAwaitingApproval) {
        return "This order is waiting for your approval";
      } else {
        return "This order has been completed";
      }
    }
  };

  const OrderTableRow = (orderData) => {
    return (
      <tr>
        {/* <td>
          {orderData.isCancelled ? <FiberManualRecord color="error" /> : null}
          {!orderData.isCancelled && (
            <FiberManualRecord
              color={orderData.isAwaitingApproval ? "warning" : "success"}
            />
          )}
        </td> */}
        <td>
          <Card variant="outlined" color={StatusToColor(orderData)}>
            <Typography
              color={StatusToColor(orderData)}
              level="body-sm"
              startDecorator={<FiberManualRecord />}
            >
              {StatusToText(orderData)}
            </Typography>
            <Typography level="title-md">
              <Link
                onClick={() =>
                  navigate(`/dash/orders/single?id=${orderData._id}`, {
                    state: orderData,
                  })
                }
              >
                {isListingDataFetching
                  ? "Loading..."
                  : listingData.results.find(
                      (l) => l._id === orderData.listingId
                    )?.name}
              </Link>
            </Typography>
          </Card>
          {/*  */}
        </td>
        <td>
          {orderData.createdAt
            ? DateTime.fromMillis(orderData.createdAt).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )
            : "(no date)"}
        </td>
      </tr>
    );
  };

  return (
    <>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <Table stickyHeader>
          <thead>
            <tr>
              <th style={{ width: "80%" }}>Order Summary</th>
              <th>{"Order Date"}</th>
            </tr>
          </thead>
          <tbody>
            {ordersData &&
              ordersData
                .filter((o) => !o.bookingDate)
                .map((o) => <OrderTableRow key={o._id} {...o} />)}
          </tbody>
        </Table>
      )}
    </>
  );
}
