import { Box, Button, Grid, Input, Typography } from "@mui/joy";
import { Information } from "iconsax-react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export default function CancellationRangeBuilder(props) {
  // eslint-disable-next-line no-unused-vars
  const { setValue } = useFormContext();
  const [cancellationRanges, setCancellationRanges] = useState(
    props.cancellationRanges ?? []
  );
  const [newCancellationDay, setNewCancellationDay] = useState(1);
  const [newCancellationPercentage, setNewCancellationPercentage] = useState(0);

  const addCancellationRange = () => {
    if (newCancellationDay < 1 || newCancellationPercentage <= 0) {
      alert(
        "Sorry, the cancellation day and percentage must be greater than 0"
      );

      return;
    }
    console.log("Add Cancellation Range");

    const lastEntry = cancellationRanges[cancellationRanges.length - 1];

    if (lastEntry && lastEntry.day >= newCancellationDay) {
      alert("Sorry, the day must be greater than the last day you entered");
      return;
    }

    if (lastEntry && lastEntry.percentage <= newCancellationPercentage) {
      alert(
        "Sorry, the percentage must be less than the last percentage you entered"
      );
      return;
    }

    const newCancellationData = [
      ...cancellationRanges,
      { day: newCancellationDay, percentage: newCancellationPercentage },
    ];

    setCancellationRanges(newCancellationData);
    setValue("cancellationRanges", newCancellationData);
  };

  // eslint-disable-next-line no-unused-vars
  const removeCancellationRange = (index) => {
    console.log("Remove Cancellation Range");
    const updatedRange = cancellationRanges.filter((item, i) => i !== index);
    setCancellationRanges(updatedRange);
    setValue("cancellationRanges", updatedRange);
  };

  useEffect(() => {
    console.log("cancellation ranges:", cancellationRanges);
    setCancellationRanges(cancellationRanges);
    setValue("cancellationRanges", cancellationRanges);
  }, []);

  return (
    <Box>
      {cancellationRanges.map((row, index) => (
        <Grid container key={index}>
          <Grid xs={2}>
            <Typography>On day:</Typography>
            <Typography fontFamily={"monospace"} fontSize={21}>
              {row.day}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography>Refund percentage:</Typography>
            <Typography fontFamily={"monospace"} fontSize={21}>
              {row.percentage}%
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Typography>&nbsp;</Typography>
            <Button
              onClick={() => removeCancellationRange(index)}
              color="danger"
              variant="plain"
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}
      {cancellationRanges.length === 0 && (
        <Typography startDecorator={<Information />}>
          No Cancellation Ranges added yet
        </Typography>
      )}
      <Box>
        <Typography fontWeight={"bold"} my={1}>
          Add a new Cancellation Range
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={5}>
            <Input
              type="number"
              startDecorator={<Typography>On day:</Typography>}
              slotProps={{
                input: {
                  min: 1,
                },
              }}
              value={newCancellationDay}
              onChange={(e) => setNewCancellationDay(e.target.value)}
            />
          </Grid>
          <Grid xs={5}>
            <Input
              type="number"
              startDecorator={
                <Typography>Refund percentage will be:</Typography>
              }
              value={newCancellationPercentage}
              onChange={(e) => setNewCancellationPercentage(e.target.value)}
            />
          </Grid>
          <Grid xs={2}>
            <Button fullWidth onClick={() => addCancellationRange()}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
