import { Box, Typography } from "@mui/joy";

export default function SectionHeader({ title, subtitle }) {
  return (
    <Box>
      <Box>
        <Typography level="h2">{title}</Typography>
        <Typography gutterBottom level="body-lg">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
