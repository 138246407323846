/* eslint-disable no-unused-vars */
import { Button, Card, Divider, Grid, Typography } from "@mui/joy";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useFormContext } from "react-hook-form";

export default function ItineraryBuilder(props) {
  const { setValue } = useFormContext();
  const [itineraries, setItineraries] = useState(props.value);
  const [newItineraryDescription, setNewItineraryDescription] = useState({
    blocks: [],
    entityMap: {},
  });

  const editItineraryItem = (idx, cs) => {
    const newArr = JSON.parse(JSON.stringify(itineraries));
    newArr[idx] = cs;
    setItineraries(newArr);
    setValue(props.name, newArr);
  };

  const addItineraryItem = () => {
    if (newItineraryDescription.blocks[0].text === "") {
      alert("Please enter an itinerary description");
      return;
    }

    let newArr = JSON.parse(JSON.stringify(itineraries));
    newArr.push(newItineraryDescription);

    setItineraries(newArr);
    setValue(props.name, newArr);
    setNewItineraryDescription({ blocks: [], entityMap: {} });
  };

  const removeItineraryItem = (idx) => {
    const splicedArray = itineraries.toSpliced(idx, 1);
    setItineraries(splicedArray);

    if (props.onChange) {
      props.onChange(splicedArray);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {itineraries.map((i, idx) => {
          return (
            <Grid key={`itinerarybuilder-${props.name}-${idx}`} xs={12}>
              <Card>
                <Typography level="body-sm">Itinerary Day {idx + 1}</Typography>
                {/* <div dangerouslySetInnerHTML={{ __html: draftToHtml(i) }} /> */}
                <Editor
                  contentState={i}
                  stripPastedStyles={true}
                  editorStyle={{
                    padding: "10px 30px",
                    border: "2px solid #d9d9d9",
                    borderRadius: "4px",
                    backgroundColor: "white",
                  }}
                  onContentStateChange={(cs) => {
                    editItineraryItem(idx, cs);
                  }}
                  toolbar={{
                    options: ["list"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                />
                <Grid container justifyContent="flex-end">
                  <Button
                    onClick={() => removeItineraryItem(idx)}
                    size="small"
                    color="danger"
                  >
                    Delete
                  </Button>
                </Grid>
              </Card>
              {itineraries.length === idx + 1 && (
                <Grid xs={12}>
                  <Divider sx={{ marginY: 2 }}>
                    Add another itinerary day
                  </Divider>

                  <Card>
                    <Editor
                      // initialContentState={newItineraryDescription}
                      placeholder={`A description of day ${
                        itineraries.length + 1
                      }`}
                      stripPastedStyles={true}
                      onContentStateChange={(cs) => {
                        // setValue(props.name, cs);
                        // console.log("content changing...", cs);
                        setNewItineraryDescription(cs);
                      }}
                      toolbar={{
                        options: ["list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        padding: "10px 30px",
                        border: "2px solid #d9d9d9",
                        borderRadius: "4px",
                        backgroundColor: "white",
                      }}
                    />
                    <Grid container>
                      <Button
                        onClick={() => addItineraryItem()}
                        color="primary"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          );
        })}
        {!itineraries ||
          (itineraries.length === 0 && (
            <Grid xs={12}>
              <Divider>Start a new itinerary</Divider>

              <Card>
                <Editor
                  // initialContentState={newItineraryDescription}
                  placeholder={`A description of day ${itineraries.length + 1}`}
                  stripPastedStyles={true}
                  onContentStateChange={(cs) => {
                    // setValue(props.name, cs);
                    // console.log("content changing...", cs);
                    setNewItineraryDescription(cs);
                  }}
                  toolbar={{
                    options: ["list"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                />
                <Grid container>
                  <Button onClick={() => addItineraryItem()} color="primary">
                    Add
                  </Button>
                </Grid>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
