/* eslint-disable no-unused-vars */
import { Card } from "@mui/joy";
import { convertFromRaw } from "draft-js";
import { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormContext } from "react-hook-form";

export default function DashEditor(props) {
  const { setValue, formState, register, setError, setFocus } =
    useFormContext();
  let contentState = props.contentState;

  /**
   * This try / catch block is here to capture
   * and reset any API data model properties
   * that were converted from strings to draft-js
   * compatible objects. Also catches any other
   * errors.
   */
  try {
    convertFromRaw(props.contentState);
  } catch (error) {
    contentState = { blocks: [], entityMap: {} };
  }

  if (props.required) {
    register(props.name, { required: true });
  }

  useEffect(() => {
    if (formState.errors[props.name]) {
      setFocus(props.name);
    }
  }, [formState.errors]);

  return (
    <Card
      color={
        formState.errors[props.name]?.type === "required"
          ? "warning"
          : "neutral"
      }
    >
      <Editor
        initialContentState={contentState}
        stripPastedStyles={true}
        editorStyle={{
          padding: "10px 30px",
          border: "2px solid #d9d9d9",
          borderRadius: "4px",
          backgroundColor: "white",
        }}
        onContentStateChange={(cs) => {
          setValue(props.name, cs);

          /**
           * We still call this in accommodation
           * Structure Builder component
           */
          if (props.onChange) {
            props.onChange(cs);
          }
        }}
        toolbar={{
          options: ["list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
      />
    </Card>
  );
}
