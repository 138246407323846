/* eslint-disable no-unused-vars */
import { Box, Button, Card, Grid, LinearProgress, Typography } from "@mui/joy";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateWizardMutation, useGetConfigQuery } from "services/osare";

export default function WizardCreate() {
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const [createWizardMutation, createWizardResult] = useCreateWizardMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [working, setWorking] = React.useState(false);

  useEffect(() => {
    if (createWizardResult.isSuccess) {
      navigate(`/dash/wizard/${createWizardResult.data.id}`);
    }
  }, [createWizardResult]);

  const WizardWorkingComponent = () => {
    return (
      <>
        <Grid xs={4}></Grid>
        <Grid xs={4}>
          <Box m={5} />
          {!working ? (
            <Card>
              <Box marginY={2}>
                <Typography marginBottom={4} textAlign={"center"}>
                  This wizard will guide you through the process of creating a
                  new Retreat. It will ask questions about your event,
                  accommodation options and about your listing.
                </Typography>

                <Button
                  onClick={() => {
                    setWorking(true);
                    createWizardMutation();
                  }}
                  fullWidth
                >
                  Start
                </Button>
              </Box>
            </Card>
          ) : (
            <Card>
              <Box marginY={2}>
                <Typography
                  marginBottom={4}
                  level="title-lg"
                  textAlign={"center"}
                >
                  ✨ Getting everything ready...
                </Typography>

                <Box marginY={2}>
                  <LinearProgress />
                </Box>

                {createWizardResult.isError && (
                  <Typography level="body-md" textAlign={"center"}>
                    {createWizardResult.error.error}
                  </Typography>
                )}
              </Box>
            </Card>
          )}
        </Grid>
        <Grid xs={4}></Grid>
      </>
    );
  };

  return (
    <Grid m={1} container spacing={2}>
      <WizardWorkingComponent />
    </Grid>
  );
}
