import { Link } from "@mui/material";

import Add from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Sheet,
  Stack,
  Table,
} from "@mui/joy";
import PreCreateModal from "pages/Dash/Components/PreCreateModal/PreCreateModal";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetListingsQuery } from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";

export default function DashListingsList() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { data, refetch, isLoading } = useGetListingsQuery("");

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (location.state && location.state.saved === true) {
      dispatch(triggerSuccessSnackbar({ open: true, message: "Saved" }));
    }
  }, [location.state]);

  const ListingListComponent = () => {
    return (
      <Stack alignItems={"flex-start"} marginTop={2}>
        <PreCreateModal type="listing" setOpen={setOpen} open={open} />

        <Button onClick={() => setOpen(true)} startDecorator={<Add />}>
          Add new listing
        </Button>

        {isLoading ? (
          <Grid container marginTop={3}>
            <Alert color="primary" startDecorator={<CircularProgress />}>
              Just a moment...
            </Alert>
          </Grid>
        ) : null}

        {data ? (
          <Sheet>
            <Table>
              <thead>
                <tr>
                  <th>Listing</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((d) => (
                  <tr key={d._id}>
                    <td>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`edit?id=${d._id}`)}
                        underline="hover"
                      >
                        {d.name}
                      </Link>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        ) : null}
      </Stack>
    );
  };

  return (
    <>
      {location.pathname === "/dash/listings" ? (
        <ListingListComponent />
      ) : (
        <Outlet />
      )}
    </>
  );
}
