export default function isEventComponentEnabled(config, componentName) {
  if (!config) {
    return false;
  }

  const accommodationComponents = config.merchantDashboard.sections.find(
    (s) => s.name === "events"
  );

  if (!accommodationComponents) return false;

  const requestedComponent = accommodationComponents.components.find(
    (c) => c.id === componentName
  );
  if (!requestedComponent) return false;

  return requestedComponent.enabled;
}
