import { Warning } from "@mui/icons-material";
import { Alert, Option, Select } from "@mui/joy";
import Box from "@mui/material/Box";
import * as React from "react";
import { useFormContext } from "react-hook-form";

export default function AccommodationSelect(props) {
  console.log("AccommodationSelect props:", props);
  const formMethods = useFormContext();
  const [selectedAccommodation, setSelectedAccommodation] = React.useState(
    props.listingData.accommodationIds
      ? props.listingData.accommodationIds
      : ["accom-select-disabled"]
  );

  return (
    <Box>
      <Select
        color={
          formMethods.formState.errors[props.name]?.type === "required" &&
          !selectedAccommodation
            ? "warning"
            : "neutral"
        }
        disabled={props.listingData.isActive}
        defaultValue={"accom-select-disabled"}
        value={selectedAccommodation[0]}
        label="Accommodation"
        onChange={(e, v) => {
          console.log("AccommodationSelect onChange:", props.name, v);
          formMethods.setValue(props.name, [v]);
          setSelectedAccommodation([v]);
        }}
      >
        <Option
          disabled
          key={`accom-select-disabled`}
          value={`accom-select-disabled`}
        >
          Please select accommodation
        </Option>
        {props.accommodations.map((a) => (
          <Option key={a._id} value={a._id}>
            {a.name}
          </Option>
        ))}
      </Select>

      {selectedAccommodation.length === 0 && (
        <Box marginTop={1}>
          <Alert startDecorator={<Warning />} color="warning">
            Please select an accommodation from the list above.
          </Alert>
        </Box>
      )}
    </Box>
  );
}
